import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL, BASE_URL } from './Config';


function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };




    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setError('Please enter both username and password');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);

            const response = await fetch( `${API_URL}/ws_login`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }
            const data = await response.json();
            if(data.status)
            { 
                localStorage.setItem('accessToken', '123456');                     
                navigate('/dashboard');
                setError(null);
            }
            else{
                setError('Incorrect username or password');
            }
        } catch (error) 
        {
            setError(error.message || 'Login failed');
            console.error('Login error:', error);
        }
    };

    return (
        <div className="login-body d-flex flex-column h-100">
            <div className="login-container">
                <div className="login-content">
                    <div className="login-content_header">
                        <span className="logo">
                            <img src="assets/img/VHP.png" alt="VHP Logo" />
                        </span>
                        <p>Please login to your account.</p>
                    </div>
                    <div style={{ float: 'left', width: '100%' }}>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <input type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
                            <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                            {error && <p className="text-danger">{error}</p>}
                            <button type="submit" className="login-submit-btn">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
