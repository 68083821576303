import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import config from "../common/Config";

const PrivacyPolicies = () => {
  const currentYear = new Date().getFullYear();

  return (

<div>
    <header id="header" class="vh-header d-flex align-items-center sticky-top">
  <div class="container position-relative d-flex align-items-center justify-content-between"> 
    <Link to="/" class="logo d-flex align-items-center me-auto me-xl-0">
    <h1 class="sitename">
      <img src="https://vhppmp.org/assets/img/Vishva_Hindu_Parishad_Logo.png" alt="विश्व हिंदू परिषद" title="विश्व हिंदू परिषद" /></h1>
    </Link>
    <nav id="navmenu" class="navmenu">
    <ul>
        <li><Link to="/Homepage" className="active"><span>Home</span></Link></li>
        <li><Link to="/Add_Subscriber_common"><span>Hindubodh</span></Link></li>
      </ul>
      <i class="mobile-nav-toggle d-xl-none bi bi-list"></i> </nav>
  </div>
</header>


<main class="main">

   
    <div class="page-title">
      <div class="container d-lg-flex justify-content-between align-items-center">
        <h1 class="mb-2 mb-lg-0">Privacy Policy</h1>
      </div>
    </div>

    <section id="starter-section" class="starter-section section">

      
      {/* <div class="container section-title" data-aos="fade-up">
        <div class="section-title-container d-flex align-items-center justify-content-between">
          <h2>Privacy Policy</h2>
        </div>
      </div> */}
<div class="main-area">
      <div class="container" data-aos="fade-up">
        <p>The terms "I”/ “We" / "Us" / "Our"/”Company” individually and collectively refer to HINDUBODH and the terms "Visitor” ”User” refer to the users. 
This Privacy Policy is an electronic record in the form of an electronic contract formed under the information Technology Act, 2000 and the rules made there under and the amended provisions pertaining to electronic documents / records in various statutes as amended by the information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature.<br/><br/>
This Privacy Policy is a legally binding document between you and HINDUBODH  (both terms defined below). The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or indirectly in electronic form, by clicking on the “I accept" tab or by use of the website or by other means) and will govern the relationship between you and HINDUBODH for your use of the website “Website” (defined below).<br/><br/>
This document is published and shall be construed in accordance with the provisions of the Information Technology (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for collection, use, storage and transfer of sensitive personal data or information.<br/><br/>
Please read this Privacy Policy carefully by using the Website, you indicate that you understand, agree and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use this Website. <br/>
By providing us your Information or by making use of the facilities provided by the Website, You hereby consent to the collection, storage, processing and transfer of any or all of Your Personal Information and Non-Personal Information by us as specified under this Privacy Policy. You further agree that such collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.
</p>
		  
		  <h3>USER INFORMATION </h3>
		  
		  
		  <p>To avail certain services on my Websites, users are required to provide certain information for the registration process namely: - a) your name, b) email address, c) contact, d) birth date, e) PIN code, f) address details, and / or your occupation, interests, and the like. The Information as supplied by the users enables us to improve my sites and provide you the most user-friendly experience.<br/><br/>

All required information is service dependent and we may use the above said user information to, maintain, protect, and improve its services (including advertising services) and for developing new services<br/><br/>

Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.
</p>
		  
		  <h3>COOKIES</h3>
		  
		  <p>To improve the responsiveness of the sites for my users, we may use "cookies", or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user's individual interests using the Identified Computer. Unless you voluntarily identify yourself (through registration, for example), I will have no way of knowing who you are, even if i assign a cookie to your computer. The only personal information a cookie can contain is information you supply (an example of this is when you ask for my Personalized Horoscope). A cookie cannot read data off your hard drive. my advertisers may also assign their own cookies to your browser (if you click on their ads), a process that i do not control. 
<br/><br/>
My web servers automatically collect limited information about your computer's connection to the Internet, including your IP address, when you visit my site. (Your IP address is a number that lets computers attached to the Internet know where to send you data -- such as the web pages you view.) Your IP address does not identify you personally. I use this information to deliver my web pages to you upon request, to tailor my site to the interests of my users, to measure traffic within my site and let advertisers know the geographic locations from where my visitors come. 
</p>
		
		  <h3>LINKS TO THE OTHER SITES</h3>
		  <p>Our policy discloses the privacy practices for my own web site only. my site provides links to other websites also that are beyond my control. We shall in no way be responsible in way for your use of such sites.</p>
		  
		  <h3>INFORMATION SHARING</h3>
		  <p>We shares the sensitive personal information to any third party without obtaining the prior consent of the user in the following limited circumstances:</p>
		  <ul>
		  	<li>When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations. </li>
			  
			  <li>I propose to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. I also ensure that these recipients of such information agree to process such information based on my instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.</li>
		  </ul>
		  
		  
		  <h3>INFORMATION SECURITY</h3>
		  <p>I take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of my data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.
<br/><br/>
All information gathered on my Website is securely stored within my controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as my security measures are, no security system is impenetrable. We cannot guarantee the security of my database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion areas is available to anyone with Internet access. 
<br/><br/>
However the internet is an ever evolving medium. I may change my Privacy Policy from time to time to incorporate necessary future changes. Of course, my use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be. 
</p>
		  
		  <h3>Grievance Redressal</h3>
		  
		  <p>Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to Mr. Dinesh Chandra Pandey ("Grievance Officer"). 
</p>
			  
	<div class="privacy-detail"> 
<span>Mr/MS -</span> Shripad Shrikant Ramdasi.<br/>
<p><span>Site : </span>vhppmp.org</p>

<strong>HINDUBODH</strong>

		  
		  <p>
		  <span>Address :</span>  VHP karyalay, 1360,  Bharat Bhavan, Natu Bag pune.
<br/>

<span>Email :</span> shripad.ramdasi@outlook.com <br/>
<span>Phone :</span> 9860471983<br/>
</p>
		  
	</div>		 	  
      </div>
</div>
    </section>

  </main>

<footer id="footer" class="vh-footer dark-background">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <div class="vh-footer-link">
                <Link to="/PrivacyPolicies">
                  <span>Privacy Policies</span>
                </Link>{" "}
                |{" "}
                <Link to="/TermsConditions">
                  <span>Terms and Conditions</span>
                </Link>{" "}
                |{" "}
                <Link to="/ReturnPolicy">
                  <span>Return Policy</span>
                </Link>{" "}
                |{" "}
                <Link to="/contactUs">
                  <span>Contact us</span>
                </Link>{" "}
                |{" "}
                <Link to="/ShippingPolicy">
                  <span>Shipping Policy</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center">
          <p style={{ float: 'left'}}>
            © <span>Copyright {currentYear}</span>{" "}
            <strong class="px-1 sitename">Vishva Hindu Parishad</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            {" "}
            Designed by{" "}
            <a href="https://rameesystems.com/" target="_blank"   style={{ float: 'right'}}>
           <img src="https://vhppmp.org/img/ramee-logo.png" alt="Ramee Systems Logo"  style={{ width: '50px', margin: '-9px 0 0 15px'}} />

            </a>{" "}
          </div>
        </div>
      </footer>

<a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> 
{/* <div id="preloader"></div> */}
</div>




  );
};

export default PrivacyPolicies;
