import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = ({ name }) => {
  return (
    <div className="thank-you-container">
      <h1>Thank You for Hindubodh Subscription. </h1>
      <div className="thank-you-actions">
        <Link to="/homepage">
          <button>Go to Home</button>
        </Link>
      </div>
    </div>
  );
};

export default ThankYou;
