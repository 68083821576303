import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import config from "../common/Config";

const ContactUs = () => {
  const currentYear = new Date().getFullYear();

  return (

<div>
<header id="header" class="vh-header d-flex align-items-center sticky-top">
  <div class="container position-relative d-flex align-items-center justify-content-between"> 
    <Link to="/Homepage" class="logo d-flex align-items-center me-auto me-xl-0">
    <h1 class="sitename">
      <img src="https://vhppmp.org/assets/img/Vishva_Hindu_Parishad_Logo.png" alt="विश्व हिंदू परिषद" title="विश्व हिंदू परिषद" /></h1>
    </Link>
    <nav id="navmenu" class="navmenu">
    <ul>
        <li><Link to="/Homepage" className="active"><span>Home</span></Link></li>
		    <li><Link to="/Add_Subscriber_common"><span>Hindubodh</span></Link></li>
      </ul>
      <i class="mobile-nav-toggle d-xl-none bi bi-list"></i> </nav>
  </div>
</header>


<main class="main">

   
    <div class="page-title">
      <div class="container d-lg-flex justify-content-between align-items-center">
        <h1 class="mb-2 mb-lg-0">Contact Us</h1>
      </div>
    </div>

    <section id="starter-section" class="starter-section section">

  
      <div class="main-area">
      <div id="contact" class="contact">

      <div class="container" data-aos="fade-up" data-aos-delay="100">
        <div class="map mb-4" data-aos="fade-up" data-aos-delay="200">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10956.137220511557!2d73.83664105541993!3d18.510171300000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c06d9a6d988d%3A0xf2e2b0c9390568a8!2sVishwa%20Hindu%20Parishad%20Pune%20Office!5e1!3m2!1sen!2sin!4v1733981892157!5m2!1sen!2sin"
            width="100%"
            height="270"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </div>

        <div class="row gy-4">

          <div class="col-lg-4">
            <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
              <i class="bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h3>Address</h3>
                <p>VHP karyalay, 1360, Bharat Bhavan, Natu Bag pune.</p>
              </div>
            </div>
            <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
              <i class="bi bi-telephone flex-shrink-0"></i>
              <div>
                <h3>Call Us</h3>
                <p>9860471983</p>
              </div>
            </div>
            <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
              <i class="bi bi-envelope flex-shrink-0"></i>
              <div>
                <h3>Email Us</h3>
                <p>shripad.ramdasi@outlook.com</p>
              </div>
            </div>
			  
			      <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
              <i class="bi bi-clock flex-shrink-0"></i>
              <div>
                <h3>Business Hours:</h3>
                <p>24*7</p>
              </div>
            </div>
            </div>

          <div class="col-lg-8">
            <form action="forms/contact.php" method="post" class="php-email-form" data-aos="fade-up" data-aos-delay="200">
              <div class="row gy-4">

                <div class="col-md-6">
                  <input type="text" name="name" class="form-control" placeholder="Your Name" required="" />
                </div>

                <div class="col-md-6 ">
                  <input type="email" class="form-control" name="email" placeholder="Your Email" required="" />
                </div>

                <div class="col-md-12">
                  <input type="text" class="form-control" name="subject" placeholder="Subject" required="" />
                </div>

                <div class="col-md-12">
                  <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                </div>

                <div class="col-md-12 text-center">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">Your message has been sent. Thank you!</div>

                  <button type="submit">Send Message</button>
                </div>

              </div>
            </form>
          </div>

        </div>

      </div>

    </div>
</div>
    </section>

  </main>


<footer id="footer" class="vh-footer dark-background">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <div class="vh-footer-link">
                <Link to="/PrivacyPolicies">
                  <span>Privacy Policies</span>
                </Link>{" "}
                |{" "}
                <Link to="/TermsConditions">
                  <span>Terms and Conditions</span>
                </Link>{" "}
                |{" "}
                <Link to="/ReturnPolicy">
                  <span>Return Policy</span>
                </Link>{" "}
                |{" "}
                <Link to="/contactUs">
                  <span>Contact us</span>
                </Link>{" "}
                |{" "}
                <Link to="/ShippingPolicy">
                  <span>Shipping Policy</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center">
          <p style={{ float: 'left'}}>
            © <span>Copyright {currentYear}</span>{" "}
            <strong class="px-1 sitename">Vishva Hindu Parishad</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            {" "}
            Designed by{" "}
            <a href="https://rameesystems.com/" target="_blank"   style={{ float: 'right'}}>
           <img src="https://vhppmp.org/img/ramee-logo.png" alt="Ramee Systems Logo"  style={{ width: '50px', margin: '-9px 0 0 15px'}} />

            </a>{" "}
          </div>
        </div>
      </footer>

<a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> 
{/* <div id="preloader"></div> */}
</div>




  );
};

export default ContactUs;
