import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const Edit_Subscriber = () => {
  const accessToken = localStorage.getItem('accessToken');  
  const navigateToSupplierList = useNavigate(); 
  const { subscriberId } = useParams();
  const [errors, setErrors] = useState({});
  const [paymentMethodCheque, setPaymentMethodCheque] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [subscription, setSubscription] = useState(1); 
  const [discount, setDiscount] = useState(0);
  const [payment, setPayment] = useState(0);
  const [isFree, setIsFree] = useState(0);
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);
  const [status, setStatus] = useState(false);

  const [formData, setFormData] = useState({
    company: '',
    name: '',
    email: '',
    phone: '',
    contact_person: '',
    contact_person_mob: '',
    contact_person_email: '',
    finance_person: '',
    finance_person_mob : '',
  });

  const validateForm = () => {
    const newErrors = {};

    // Company validation
    if (!formData.company) {
      newErrors.company = "Mention company Name.";
    } else if (formData.company.length < 2) {
      newErrors.company = "Company name must consist of at least 2 characters.";
    } else if (formData.company.length > 255) {
      newErrors.company = "Company name must not exceed 255 characters.";
    }

    // Name validation
    if (!formData.name) {
      newErrors.name = "Mention Name.";
    } else if (formData.name.length < 2) {
      newErrors.name = "Name must consist of at least 2 characters.";
    } else if (formData.name.length > 255) {
      newErrors.name = "Name must not exceed 255 characters.";
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is Required.";
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be between 10 and 12 digits.";
    }

    // Contact person validation
    if (!formData.contact_person) {
      newErrors.contact_person = "Mention person Name.";
    } else if (formData.contact_person.length < 2) {
      newErrors.contact_person = "Contact person name must consist of at least 2 characters.";
    } else if (formData.contact_person.length > 255) {
      newErrors.contact_person = "Contact person name must not exceed 255 characters.";
    }

    // Contact person mobile validation
    if (!formData.contact_person_mob) {
      newErrors.contact_person_mob = "Contact person mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.contact_person_mob)) {
      newErrors.contact_person_mob = "Contact person mobile must be between 10 and 12 digits.";
    }



    // Finance person validation
    if (!formData.finance_person) {
      newErrors.finance_person = "Mention Finance Person Name.";
    } else if (formData.finance_person.length < 2) {
      newErrors.finance_person = "Finance person name must consist of at least 2 characters.";
    } else if (formData.finance_person.length > 255) {
      newErrors.finance_person = "Finance person name must not exceed 255 characters.";
    }

    
    if (!formData.finance_person_mob) {
      newErrors.finance_person_mob = "finance mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.finance_person_mob)) {
      newErrors.finance_person_mob = "finance person mobile must be between 10 and 12 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let fieldErrors = {};

    // Validate the input field immediately as the user types
    switch (name) {
      case 'company':
        if (!value) {
          fieldErrors.company = "Mention company Name.";
        } else if (value.length < 2) {

          fieldErrors.company = "Company name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.company = "Company name must not exceed 255 characters.";
        }
         
        // Clear error if all conditions are met
        if (!fieldErrors.company) {
          //delete fieldErrors.company;
           fieldErrors.company = '';
        }
        break;

      case 'name':
        if (!value) {
          fieldErrors.name = "Mention Name.";
        } else if (value.length < 2) {
          fieldErrors.name = "Name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.name = "Name must not exceed 25 characters.";
        }
        if (!fieldErrors.name) {
          fieldErrors.name = '';
        }
        break;

      case 'phone':
        if (!value) {
          fieldErrors.phone = "Phone is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.phone = "Phone number must be between 10 and 12 digits.";
        }
        if (!fieldErrors.phone) {
          
          fieldErrors.phone = '';
        }
        break;

      case 'contact_person':
        if (!value) {
          fieldErrors.contact_person = "Mention person Name.";
        } else if (value.length < 2) {
          fieldErrors.contact_person = "Contact person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.contact_person = "Contact person name must not exceed 255 characters.";
        }
        if (!fieldErrors.contact_person) {
          fieldErrors.contact_person = '';
        }
        break;

      case 'contact_person_mob':
        if (!value) {
          fieldErrors.contact_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.contact_person_mob = "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.contact_person_mob) {
          fieldErrors.contact_person_mob = '';
        }
        break;

      case 'finance_person':
        if (!value) {
          fieldErrors.finance_person = "Mention Finance Person Name.";
        } else if (value.length < 2) {
          fieldErrors.finance_person = "Finance person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.finance_person = "Finance person name must not exceed 255 characters.";
        }
        if (!fieldErrors.finance_person) {
          fieldErrors.finance_person = '';
        }
        break;

      case 'finance_person_mob':
        if (!value) {
          fieldErrors.finance_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.finance_person_mob = "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.finance_person_mob) {
          fieldErrors.finance_person_mob = '';
        }
        break;

      default:
        break;
    }

    // Update errors state for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors
    }));
  };

{/* <script src="https://checkout.razorpay.com/v1/checkout.js"></script> */}


  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);
      document.body.appendChild(script);
    });
  };

    const handlePayment = async (amount, fname, lname, email, contact, memberId) => {
      if (payment === '3') {
        try {
          // Load Razorpay script
          await loadRazorpayScript();

          // Convert amount to paise (1 INR = 100 paise)
          const amountInPaise = amount * 100;  // Or use 1 * 100 for testing
          
          const formData = new FormData();
          formData.set("amount", amountInPaise);
          formData.set("memberId", memberId);  // Send memberId with the order request

          // Get the order data from the backend using Axios
          const response = await axios.post(
            `${config.API_URL}/ws_make_payment`, // Make sure this is correct URL
            formData
          );
          const orderData = response.data; // Order data from backend
          const memberIdFromResponse = orderData.memberId; // Retrieve memberId from response

          // Razorpay options
          const options = {
            key: "rzp_live_0YiizqzM2Fk8dJ",  // Your Razorpay key
            amount: amountInPaise,  // Amount in paise
            currency: orderData.currency,  // Currency from backend
            name: fname + " " + lname,  // User name
            handler: function (response) {
              const paymentId = response.razorpay_payment_id;
              console.log(response);
              console.log("Payment ID: " + paymentId);

              if (paymentId) {
                Swal.fire({
                  title: "Success!",
                  text: "Subscriber added successfully.",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });

                // Pass memberId with paymentId to update the status
                updatePaymentStatus(memberIdFromResponse, paymentId);  // Use memberId from response
              }
            },
            modal: {
              escape: false, // Prevent closing modal by clicking outside
            },
            prefill: {
              name: fname + " " + lname,
              email: email === '' ? '' : email,  
              contact: contact, 
            },
            theme: {
              color: "#F37254", // Customize theme color
            },
          };

          const rzp = new window.Razorpay(options);

          // Handle payment failure
          rzp.on("payment.failed", function (response) {
            // Handle failure (log or show a message)
            console.error("Payment failed:", response);
          });

          // Open Razorpay payment modal
          rzp.open();
        } catch (error) {
          console.error(
            "Error loading Razorpay script or during payment process:",
            error
          );
        }
      } else {
        Swal.fire({
          title: "Success!",
          text: "Subscriber updated successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        updatePaymentStatus(memberId, '000');  // Update payment status without actual payment
      }
    };

  const updateSubscriberForm = async () => {
    // if (!validateForm()) return; 
    // setIsSubmitBtn(true);
    const form = document.getElementById('updateSubscriberform');
    const formData = new FormData(form);
    const amount = totalAmount;  
    const fname = formData.get('fname');
    const lname = formData.get('lname');
 
    try {
      const response = await axios.post(`${config.API_URL}/ws_update_subscriber`, formData, {
        headers: {
          authorization: `Bearer`,
        }
      });
      let member_id = null;
      const result = response.data;
      member_id = result.member_id;
      if (result.success == true)
      {
             handlePayment(amount, fname, lname, member_id);
             navigateToSupplierList(`/subscriber`);
      }
      else
      {
        setIsSubmitBtn(false);
        Swal.fire({
          title: "Failed!",
          text: result.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
      } catch (error) {
        console.error("Error accepting order:", error);
      }
  };

    const FetchPrice = async () => {
      try {
          const response = await axios.get(`${config.API_URL}/ws_get_price`);
          if (response.data.status) {
            const amount = parseFloat(response.data.data, 10);
            setPrice(amount);
            setTotalAmount(amount);
          } else {
              console.error('Error: ', response.data.message);
          }
      } catch (error) {
          console.error('Error fetching price:', error);
        }
    };

  const updatePaymentStatus = async (memberId, paymentId) => {
    try {
        const formData = new FormData();
        formData.set('memberId',memberId); 
        formData.set('paymentId',paymentId); 
        const response = await axios.post(`${config.API_URL}/ws_update_payment_status`, formData);
        if (response.data.status) {
          Swal.fire({
            title: "Success!",
            text: "Subscriber updated successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
            console.error('Error: ', response.data.message);
        }
    } catch (error) {
        console.error('Error fetching price:', error);
      }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = '/login';
      return;
    }
    FetchPrice();
    fetchEditSupplierData();
  }, [status]);

    const calculateAmountChange = () => {
      let amount = 0;

      if(subscription != 0)
      {
        amount = subscription * price;
      }

      if(discount > 0)
        {
          let percentage = ((amount * discount)/100);
          amount = amount - percentage;
        }

        // for free payment method
      if(payment == '4')
      {
        setTotalAmount(0);   
      }
      else
      {
        setTotalAmount(amount);   
      }
    };

    const handlePaymentMethod = (value) =>{
      if(value === '2'){
          setPaymentMethodCheque(1);
        }
      else{
          setPaymentMethodCheque(0);
      }

      if(value === '4')
      {
        setIsFree(1);
        setTotalAmount(0);
        setDiscount(0);
      }
      // else if(value === '3')
      // {
        
      // }
      else
      {
        setIsFree(0);
        let amount = subscription * price;
        if(discount > 0)
          {
            let percentage = ((amount * discount)/100);
            amount = amount - percentage;
          }
          
        setTotalAmount(amount);
        // calculateAmountChange();
      }
    }

    useEffect(()=>{
      calculateAmountChange(); 
    },[discount, subscription]);

    const fetchEditSupplierData = async () => {
      const formData = new FormData();
      formData.append('id', subscriberId);
      const apiUrl = `${config.API_URL}/ws_subscriber_edit`;
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch supplier data');
        }

        const data = await response.json();
        // alert(typeof(data.data.status));
        
        if(data.data.status === '0'){
          setStatus(true);
        }
        else{
          setStatus(false);
        }

        setFormData({
          id : data.data.id,
          fname: data.data.fname,
          mname: data.data.mname,
          lname: data.data.lname,
          email: data.data.email,
          phone: data.data.contact,
          address: data.data.addr1,
          address2: data.data.addr2,
          address3: data.data.addr3,
          city: data.data.city,
          state: data.data.state,
          postal_code: data.data.pincode,
          birth_date: data.data.birth_date,
          renewal_date:data.data.renewal_date

        });

    
      } catch (error) {
        console.error('Error fetching subscriber data:', error);
      }
    };

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a>  &gt; <span className="last-crumb">Edit Subscriber</span> </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard" style={{ position: 'relative' }}>
                <div className='add-supplier-popup'>
                  <form id='updateSubscriberform'>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                            <label htmlFor="fname" className="col-form-label">First Name *</label>
                            <input type="text" className="form-control" name="fname" id="fname" defaultValue={formData.fname} onChange={handleChange} />
                            <input type="hidden" className="form-control" name="renewal_date"  value={formData.renewal_date} />
                            <input type="hidden" className="form-control" name="member_id"  value={formData.id} />
                          </div>
                          <div className="col-sm-12">
                            {errors.fname && <span className="text-danger">{errors.fname}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                          <label htmlFor="mname" className="col-form-label">Middle Name </label>
                            <input type="text" className="form-control" name="mname" id="mname" defaultValue={formData.mname} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.mname && <span className="text-danger">{errors.mname}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                          <label htmlFor="lname" className="col-form-label">Last Name *</label>
                            <input type="text" className="form-control" name="lname" id="lname" defaultValue={formData.lname} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.lname && <span className="text-danger">{errors.lname}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                        <label htmlFor="birthDate" className="col-form-label">Birth Date *</label>
                        <input type="date" className="form-control" name="birthDate" defaultValue={formData.birth_date} id="birthDate" />
                      </div>
                       <div className="col-sm-12">
                            {errors.lname && <span className="text-danger">{errors.lname}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                            <label htmlFor="email" className="col-form-label">Email Address </label>
                            <input type="email" className="form-control" name="email" id="email" defaultValue={formData.email} onChange={handleChange} />
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                            <label htmlFor="phone" className="col-form-label">Phone Number *</label>
                            <input type="number" className="form-control" name="phone" id="phone" defaultValue={formData.phone} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.phone && <span className="text-danger">{errors.phone}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address" className="col-form-label">Address</label>
                        <input type="text" className="form-control" name="address" defaultValue={formData.address} id="address" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address2" className="col-form-label">Line 2</label>
                        <input type="text" className="form-control" name="address2" defaultValue={formData.address2} id="address2" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address3" className="col-form-label">Line 3</label>
                        <input type="text" className="form-control" name="address3" defaultValue={formData.address3} id="address3" />
                      </div>
                  
                      <div className="col-sm-3">
                        <label htmlFor="city" className="col-form-label">Town / City / Village</label>
                        <input type="text" className="form-control" name="city" defaultValue={formData.city} id="city" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="state" className="col-form-label">State</label>
                        <input type="text" className="form-control" name="state" defaultValue={formData.state} id="state" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="postal_code" className="col-form-label">Pin Code</label>
                        <input type="text" className="form-control" name="postal_code" defaultValue={formData.postal_code} id="postal_code" />
                      </div>
                      
                    </div>


                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                          <label htmlFor="subscriber_plan" className="col-form-label">Subscription</label>
                          <select
                              name="subscriber_plan"
                              value={subscription}
                              className="form-select orgselect"
                              onChange={(e) => setSubscription(parseInt(e.target.value))}>
                              <option value="0">Select</option>
                              <option value="1">1 Year</option>
                              <option value="2">2 Years</option>
                          </select>
                      </div>
                      <div className="col-sm-3">
                          <label htmlFor="discount" className="col-form-label">Discount</label>
                          <select
                              name="discount"
                              className="form-select orgselect"
                              value={discount}
                              onChange={(e) => setDiscount(parseInt(e.target.value))}
                              disabled={isFree === 1} 
                              defaultValue={0}
                          >
                              <option value="0">Select Discount</option>
                              <option value="10">10 %</option>
                              <option value="50">50 %</option>
                              <option value="100">100 %</option>
                          </select>
                      </div>
                      <div className="col-sm-3">
                          <label htmlFor="amount" className="col-form-label">Amount</label>
                          <input type="text" className="form-control" name="amount" id="amount" value={totalAmount} readOnly />
                      </div>
                  </div>
                    <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                        <label htmlFor="payment_method" className="col-form-label">Payment Method</label>
                        <select 
                                name="payment_method" 
                                value={payment} 
                                onChange={(e) => {
                                    setPayment(e.target.value); 
                                    handlePaymentMethod(e.target.value); 
                                }} 
                                className="form-select orgselect" >
                                <option value="0">Select Method</option>
                                <option value="1">Cash</option>
                                <option value="2">Cheque</option>
                                <option value="3">Online</option>
                                <option value="4">Free</option>
                            </select>
                      </div>
                    {paymentMethodCheque == 1 && (
                      <>
                      <div className="col-sm-3">
                        <label htmlFor="chequeDate" className="col-form-label">Cheque Date</label>
                        <input type="date" className="form-control" name="chequeDate" id="chequeDate" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="chequeNumber" className="col-form-label">Cheque Number</label>
                        <input type="text" className="form-control" name="chequeNumber" id="chequeNumber" />
                      </div>
                    </>
                    )}
                    </div>
                    <div className='row mb-3 padd-l-13px'>
                    <div className='col-12 col-lg-12 col-sm-12'>
                      <button 
                          onClick={updateSubscriberForm}
                          type="button" 
                          className="float-right-btn common-popup-btn" 
                          disabled={isSubmitBtn}
                      >
                          Update Subscriber
                      </button>
                  </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Edit_Subscriber; 