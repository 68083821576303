import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import config from "../common/Config";

const ShippingPolicy = () => {
  
const currentYear = new Date().getFullYear();
  return (

<div>
    <header id="header" class="vh-header d-flex align-items-center sticky-top">
  <div class="container position-relative d-flex align-items-center justify-content-between"> 
    <Link to="/" class="logo d-flex align-items-center me-auto me-xl-0">
    <h1 class="sitename">
      <img src="https://vhppmp.org/assets/img/Vishva_Hindu_Parishad_Logo.png" alt="विश्व हिंदू परिषद" title="विश्व हिंदू परिषद" /></h1>
    </Link>
    <nav id="navmenu" class="navmenu">
    <ul>
        <li><Link to="/Homepage" className="active"><span>Home</span></Link></li>
		<li><Link to="/Add_Subscriber_common"><span>Hindubodh</span></Link></li>
      </ul>
      <i class="mobile-nav-toggle d-xl-none bi bi-list"></i> </nav>
  </div>
</header>


<main class="main">

   
    <div class="page-title">
      <div class="container d-lg-flex justify-content-between align-items-center">
        <h1 class="mb-2 mb-lg-0">Shipping Policy</h1>
      </div>
    </div>

    <section id="starter-section" class="starter-section section">

      
      {/* <div class="container section-title" data-aos="fade-up">
        <div class="section-title-container d-flex align-items-center justify-content-between">
			
        <h2>Shipping Policy</h2>
         
        </div>
      </div> */}
      <div class="main-area">
      <div class="container" data-aos="fade-up">
        <p>This Delivery Policy, together with the Terms of Use, describes Hindubodh Masika policies and procedures towards delivery of products purchased on vhppmp.org makes all commercially reasonable endeavours to ensure that the products are delivered to users in accurate, good condition & timely fashion.<br/><br/>
Users are required to peruse and understand the terms of this Delivery Policy. If you do not agree to the terms contained in this Delivery Policy, you are advised not to accept the Terms of Use and the Delivery Policy and may forthwith leave and stop using the Website. The terms contained in this Delivery Policy shall be accepted without modification and accordingly, you agree to be bound by the terms contained herein.

</p>
		  
		  <h3>Terms of Delivery</h3>
		  
		  <p><span>1.</span> Hindubodh Masika partners with third party logistic service providers in order to effectuate product delivery to users. Details of the Logistic Partner who will be processing the delivery of the purchased product(s) will be provided to the user upon the purchased product(s) being handed over to the Logistic Partner by Hindubodh Masika. The user will also be provided with an approximate days of delivery of the purchased product. Hindubodh Masika may also effectuate product delivery to users on its own without engaging Logistic Partners.<br/><br/>
<span>2.</span> While Hindubodh Masika aims to provide its services through the website and ensures the delivery of its products all across India, currently, Hindubodh Masika has a select list of areas where delivery can be undertaken. At the time of placing an order for purchase of products on the Website, Users are required to enter their pin-code details to verify if deliveries can be carried out in their areas. If the area where the User wishes that the purchased Products be delivered is not within Hindubodh Masika recognised delivery network, Hindubodh Masika will not be able to process the order further.<br/><br/>
<span>3.</span> Prior to making payments on the Website for the purchase of Products, the User will be prompted to provide a shipping address. While entering shipping address details, the User should ensure to provide correct, complete and accurate information along with sufficient landmarks in order to aid identification of the address. Any failure in delivering the purchased Products arising out of the User’s failure to provide correct, complete and accurate information shall not hold Hindubodh Masika liable at any point in time.<br/><br/>
<span>4.</span> A maximum of 3 (three) attempts shall be made to deliver purchased Products to the User. Should the User continue to remain unavailable after 3 (three) attempts, Hindubodh Masika reserves the right to cancel the order pertaining to the purchased Products at its sole discretion. While Hindubodh Masika shall make reasonable endeavours in ensuring that purchased Products are delivered to its Users in a timely manner, delivery may be delayed on account of:<br/><br/>
•	logistical issues beyond Hindubodh Masika’ control<br/>
•	unsuitable weather conditions <br/>
•	political disruptions, epidemic, pandemic, strikes, employee-lockouts, etc.<br/>
•	acts of God such as floods, earthquakes, etc.<br/>
•	other unforeseen circumstances.<br/><br/>
			
<span>5.</span> In such events of delay, Hindubodh Masika shall make reasonable attempt at proactively intimating the User by writing to the User on his/her registered email account and/or mobile number. Hindubodh Masika disclaims all liabilities that may arise on account of its failure to intimate the User of anticipated delays in the delivery of purchased Products on the Website. Further, Hindubodh Masika shall be under no obligation to compensate the User for any mental agony or any tortuous claim that may otherwise arise on account of a delay in the shipment and delivery or use of the purchased Products.<br/><br/>
<span>6.</span> Hindubodh Masika, as an internal process, undertakes multiple diligences to ensure that their delivery executives and the individuals employed by their Logistic Partners are individuals with the highest regard for ethics and integrity. However, it is not possible for Hindubodh Masika to ensure that its own delivery executives or employees of its Logistic Partners behave in a fashion that exudes thorough professionalism, competence and good mannerism. It is expressly clarified that any ill-mannerism, impoliteness, discourtesy or offensiveness shown by Hindubodh Masika’ delivery executives or by the employees of the Logistic Partners is beyond Hindubodh Masika’ control and any issue arising between a User and Hindubodh Masika’ delivery executive or an employee of the Logistic Provider will have to be resolved by the User, independently.<br/><br/>
<span>7.</span> Upon the successful placing of an order on the Website and after Hindubodh Masika has successfully handed over the purchased Product(s) to its Logistic Partner, the User will receive a unique tracking identity number, which will enable the User in tracking the status of delivery of the purchased Products. The User may use the tracking identity number on the Website or the website and/or the mobile application of the Logistic Partner to check the whereabouts of the purchased Product and the estimated time of its delivery. Hindubodh Masika shall make reasonable attempts in ensuring that the tracking status of the purchased Products is updated in a timely manner. However, Hindubodh Masika does not guarantee the accuracy of the tracking status since it is updated on a real-time basis and is subject to inconsistencies arising out of time-lags in updating the information and other technicalities beyond Hindubodh Masika’ control.<br/><br/>
<span>8.</span> Hindubodh Masika reserves the right to charge shipping charges on all the Products on the website, the payment for which is made by any online mode of payment. If charged, such charges shall not be refunded to the user on cancellations or returns of the purchased Products, even in the case of a Product having a defect at the time of delivery (for reasons attributable to, and accepted by Hindubodh Masika after due verification at its sole discretion). Title and risk of loss for all purchased Products shall pass onto the User upon the delivery of the purchased Products to the User.<br/><br/>
<span>9.</span> Return and exchange of purchased Products shall be carried out by Hindubodh Masika’ reverse-logistics partners or its own delivery executives. Further details on how Users may process returns and exchanges of purchased Products have been set out under the Return and Refund Policy.<br/><br/>
<span>10.</span> Shipping and Delivery charges<br/>
We shall charge the shipping and delivery fees as mentioned below from you. Shipping charges may vary based on the order value of the Product that you have added to the cart. You agree that we are authorized to collect, on behalf of the Logistic Partner, the shipping and delivery fees for the delivery service provided by the Logistic Partner. In the event we charge a shipping fees for the delivery of a purchased Product, such shipping fees shall not be refunded by us for any return or cancellation of the Product. You acknowledge and accept that the title and risk of all Products ordered by you shall pass on to you upon the delivery of the purchased Products to you.

</p>	  
			 	  
      </div>
</div>
    </section>

  </main>


 <footer id="footer" class="vh-footer dark-background">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <div class="vh-footer-link">
                <Link to="/PrivacyPolicies">
                  <span>Privacy Policies</span>
                </Link>{" "}
                |{" "}
                <Link to="/TermsConditions">
                  <span>Terms and Conditions</span>
                </Link>{" "}
                |{" "}
                <Link to="/ReturnPolicy">
                  <span>Return Policy</span>
                </Link>{" "}
                |{" "}
                <Link to="/contactUs">
                  <span>Contact us</span>
                </Link>{" "}
                |{" "}
                <Link to="/ShippingPolicy">
                  <span>Shipping Policy</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center">
          <p style={{ float: 'left'}}>
            © <span>Copyright {currentYear}</span>{" "}
            <strong class="px-1 sitename">Vishva Hindu Parishad</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            {" "}
            Designed by{" "}
            <a href="https://rameesystems.com/" target="_blank"   style={{ float: 'right'}}>
           <img src="https://vhppmp.org/img/ramee-logo.png" alt="Ramee Systems Logo"  style={{ width: '50px', margin: '-9px 0 0 15px'}} />

            </a>{" "}
          </div>
        </div>
      </footer>

<a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> 
{/* <div id="preloader"></div> */}
</div>




  );
};

export default ShippingPolicy;
