 const Config = {

    API_URL: 'https://vhppmp.org/backend', 
    BASE_URL : 'https://vhppmp.org'
    
    // API_URL: 'http://localhost/hindubodh_old',
    // BASE_URL: 'http://localhost:3000',
};
  
export default Config;
  