import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './components/About';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import DataTable from './components/DataTable';
import Subscriber from './components/Master/Subscriber/Subscriber';
import Add_Subscriber from './components/Master/Subscriber/Add_Subscriber';
import Add from './components/Master/Subscriber/Add';
import Edit_Subscriber from './components/Master/Subscriber/Edit_Subscriber';
import BulkEmail from './components/BulkEmail/BulkEmail';
import History_Subscriber from './components/Master/Subscriber/History_Subscriber';
import Homepage from './components/LocalHome/Homepage';
import PrivacyPolicies from './components/LocalHome/PrivacyPolicies';
import ReturnPolicy from './components/LocalHome/ReturnPolicy';
import ShippingPolicy from './components/LocalHome/ShippingPolicy';
import TermsConditions from './components/LocalHome/TermsConditions'; 
import ContactUs from './components/LocalHome/ContactUs'; 
import Subscribe from './components/LocalHome/Subscribe'; 
import Add_Subscriber_common from './components/Master/Subscriber/Add_Subscriber_common';
import ThankYou from './components/LocalHome/ThankYou';


function App() {
  return (
    <Router basename="">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/DataTable" element={<DataTable />} />
        <Route path="/login" element={<Login />} />
        <Route path="/About" element={<About />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/:prefix" element={<Dashboard />} />
        <Route path="/subscriber" element={<Subscriber />} />
        <Route path="subscriber/add_subscriber" element={<Add_Subscriber />} />
        <Route path="Add_Subscriber_common" element={<Add_Subscriber_common />} />
        <Route path="subscriber/Add" element={<Add />} />
        <Route path="subscriber/edit_subscriber/:subscriberId" element={<Edit_Subscriber />} />
        <Route path="subscriber/bulk_email" element={<BulkEmail />} />
        <Route path="subscriber/subscriber_history/:subscriberId" element={<History_Subscriber />} />
        <Route path="Homepage" element={<Homepage />} />
        <Route path="PrivacyPolicies" element={<PrivacyPolicies />} />
        <Route path="ReturnPolicy" element={<ReturnPolicy />} />
        <Route path="ShippingPolicy" element={<ShippingPolicy />} />
        <Route path="TermsConditions" element={<TermsConditions />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="Subscribe" element={<Subscribe />} />
        <Route path="ThankYou" element={<ThankYou />} />
      </Routes>
    </Router>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
 <React.StrictMode>
    <App />
 </React.StrictMode>
);
