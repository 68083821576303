import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import config from "./common/Config";
import 'rsuite/dist/rsuite.min.css';



const Dashboard = () => {
    const [statisticsData, setStatisticsData] = useState(null); 

    const accessToken = localStorage.getItem('accessToken'); 

    useEffect(() => {
        if (accessToken === null) {
        window.location.href = '/login';
        return;
        }
    }, []); 


    const fetchData = async () => 
    {        
        const formData = new FormData();
        const apiUrl = `${config.API_URL}/ws_dashboard_count`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch members data');
            }

            const data = await response.json();
            setStatisticsData(data.data);
        } catch (error) {
            console.error('Error fetching members data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 

    return (
        <div>
            <Header />

            <nav className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> 
                            <span className="last-crumb">Dashboard</span>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <div className="show-order-area dashboard">
                                <div className="col-6 col-lg-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-6">
                                            <div className="info-card sales-card leaf-green">
                                                <div className="card-body">
                                                    
                                                        <p> Total Subscribers </p>
                                                        <b id="sales_order_grand_total">{statisticsData ? statisticsData.total_count : '0'}</b>{' '}
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <div className="info-card sales-card tomatored">
                                                <div className="card-body">
                                                   
                                                        <p> Active Subscribers </p>
                                                        <b id="delivered_invoice_grand_total">{statisticsData ? statisticsData.active_count : '0'}</b>{' '}
                                                  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <div className="info-card sales-card babyellow">
                                                <div className="card-body">
                                                 
                                                        <p> Inactive Subscribers </p>
                                                        <b id="sage_push_invoice_grand_total">{statisticsData ? statisticsData.inactive_count : '0'}</b>{' '}
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
        </div>
        </div>
        </div>
        </div>
        </div>
        <Footer />
        </div>

    );
};

export default Dashboard;
