import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";
import Swal from 'sweetalert2';

const BulkEmail = () => {
  const accessToken = localStorage.getItem('accessToken');  
  const navigateToBulkEmail = useNavigate(); 
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);


  const submitBulkEmailForm = async () => {
    setIsSubmitBtn(true);
    var msg = $('#message').val();
  
    // Check if the message is empty
    if (msg === '') {
      Swal.fire({
        title: "Failed!",
        text: 'Please enter a message',
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      setIsSubmitBtn(false);
      return;
    }
  
    const form = document.getElementById('sendBulkEmailform');
    const formData = new FormData(form);
  
    try {
      // Send the email request to the backend
      const response = await axios.post(`${config.API_URL}/ws_bulk_email`, formData, {
        headers: {
          authorization: `Bearer`,
        }
      });
  
      // Log the response from the backend
      const result = response.data;
      console.log(result);
  
      // Check for success status from the response
        Swal.fire({
          title: "Success!",
          text: "Bulk email sent successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        $('#message').val('');
        navigateToBulkEmail(`/subscriber/bulk_email`); 
  
    } catch (error) {
      console.error("Error during bulk email sending:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error while sending emails. Please try again.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    } finally {
      setIsSubmitBtn(false); // Make sure to reset the submit button state
    }
  };
  

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = '/login';
      return;
    }
  }, []);

  useEffect(()=>{
  
  },[]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a>  &gt; <span className="last-crumb">Bulk Email</span> </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard" style={{ position: 'relative' }}>
                <div className='add-supplier-popup'>
                  <form id='sendBulkEmailform'>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                            <label htmlFor="message" className="col-form-label">Message <span style={{color:'red'}}>*</span></label>
                            <textarea className="form-control" name="message" id="message" rows="4" cols="100" placeholder="Enter your message here">
                            </textarea>
                          </div>
                      </div>
                    </div>
                    <div className='row mb-3 padd-l-13px'>
                    <div className='col-12 col-lg-12 col-sm-12'>
                      <button 
                          disabled={isSubmitBtn}
                          onClick={submitBulkEmailForm}
                          type="button" 
                          className="float-right-btn common-popup-btn">
                          Bulk Email
                      </button>
                  </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BulkEmail; 