function OrderHistory() {
  return (
    <div>
      <h1>About Us</h1>
      <p>This is the content for the About page.</p>
      {/* Add specific design elements for About page */}
    </div>   
  );
}

export default OrderHistory;
