import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import config from "../../common/Config";

const Subscriber = () => {
  const supplierTableRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);
  const [status, setStatus] = useState(0);
  const accessToken = localStorage.getItem("accessToken");

  const initializeSubscriberTable = () => {
    const $table = $(supplierTableRef.current);
    const searchValue = $("#searchValue").val();
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/ws_subscriber_list`,
        type: "POST",
        headers: {
          authorization: `Bearer`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue,
            status: status,
          });
        },
        dataSrc: function (json) {
          return json.data;
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        {
          data: null,
          width: "6%",
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          data: null,
          render: function (data, type, row) {
            return `<a href="${config.BASE_URL}subscriber/subscriber_history/${row.id}" >${row.fname} ${row.mname} ${row.lname}</a>`;
          },
        },
        { data: "contact" },
        { data: "email" },
        {
          data: null,
          render: function (data, type, row) {
            if (!row.reg_date || row.reg_date === null) {
              return "N/A";
            }
            const dateParts = row.reg_date.split("-");
            const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
            return formattedDate;
          }, className: 'tabletax',

        },
        {
          data: null,
          render: function (data, type, row) {
            if (!row.renewal_date || row.renewal_date === null) {
              return "N/A";
            }
            const dateParts = row.renewal_date.split("-");
            const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
            return formattedDate;
          }, className: 'tabletax',
        },
        { data: null,
          render:function(data, type, row){
            return row.subscription_type+' '+ (row.subscription_type  === '1' ? 'year':'years');  
          }, className: 'tabletax',
        },
        { data: "city" },
        {
          data: null,
          render: function (data, type, row) {
            if (row.status == 1) {
              return `<span style="background-color: green; color: white; padding: 5px; border-radius: 3px;">Active</span>`;
            } else {
              return `<span style="background-color: yellow; color: black; padding: 5px; border-radius: 3px;">Inactive</span>`;
            }
          },
        },
        {
          data: null,
          title: "Action",
          width: "7%",
          render: (data, type, row) => `
            <a href="/subscriber/edit_subscriber/${row.id}" class="icon editbtn" title="Edit" >
              <i class="fa fa-pencil"></i>
            </a>`,
        },
      ],
      columnDefs: [
        {
          orderable: false,
          targets: "_all",
        },
      ],

      pageLength: 10,
    });

    //   $table.on('change', '.switch-input', function() {
    //     const id = $(this).data('id');
    //     const status = this.checked ? 1 : 0;

    //     handleChangeStatus(id, status);
    // });
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/login";
      return;
    }
    
    initializeSubscriberTable();
    return () => {
      if (
        supplierTableRef.current &&
        $.fn.DataTable.isDataTable(supplierTableRef.current)
      ) {
        $(supplierTableRef.current).DataTable().destroy();
      }
    };
  }, [searchValue, status]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <span className="last-crumb">Subscriber List</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div
                className="show-order-area dashboard"
                style={{ position: "relative" }}
              >
                <div className="row">
                  <div className="col-12 col-lg-3 col-sm-3">
                    <select
                      name="status"
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                    >
                      <option value={0}>All</option>
                      <option value={1}>Active</option>
                      <option value={2}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-12 col-lg-8 col-sm-8">
                    <div style={{ float: "right", marginBottom: "20px" }}>
                      <Link
                        to={`${config.BASE_URL}/subscriber/add_subscriber`}
                        className="create-catalogue-btn"
                      >
                        Add Subscriber
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="verical-scrl-wrapper common-table supplier-table"
                    id="style-3"
                  >
                    <table
                      className="table table-bordered dataTable"
                      id="neworderTable"
                      ref={supplierTableRef}
                    >
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center'}}>#</th>
                          <th style={{ textAlign: 'center'}}>Name</th>
                          <th style={{ textAlign: 'center'}}>Mobile</th>
                          <th style={{ textAlign: 'center'}}>Email</th>
                          <th style={{ textAlign: 'center'}}>Reg Date</th>
                          <th style={{ textAlign: 'center'}}>Renewal Date</th>
                          <th style={{ textAlign: 'center'}}> Plan</th>
                          <th style={{ textAlign: 'center'}}>City</th>
                          <th style={{ textAlign: 'center'}}>Status</th>
                          <th style={{ textAlign: 'center'}}>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Subscriber;
