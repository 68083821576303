import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import config from "../common/Config";

const Homepage = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <header
        id="header"
        class="vh-header d-flex align-items-center sticky-top"
      >
        <div class="container position-relative d-flex align-items-center justify-content-between">
          {" "}
          <Link
            to="/"
            class="logo d-flex align-items-center me-auto me-xl-0"
          >
            <h1 class="sitename">
              <img
                src="https://vhppmp.org/assets/img/Vishva_Hindu_Parishad_Logo.png"
                alt="विश्व हिंदू परिषद"
                title="विश्व हिंदू परिषद"
              />
            </h1>
          </Link>
          <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <Link to="/Homepage" className="active">
                  <span>Home</span>
                </Link>
              </li>
              <li>
                <Link to="/Add_Subscriber_common">
                  <span>Hindubodh</span>
                </Link>
              </li>
            </ul>
            <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </header>

      <main class="main">
        <section className="banner-area">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <img
              src="https://vhppmp.org/assets/img/post-slide-1.jpg"
              alt="विश्व हिंदू परिषद"
              title="विश्व हिंदू परिषद"
            />
          </div>
        </section>

        <section id="about" class="about section">
          <div class="container section-title" data-aos="fade-up">
            <h3>
              About हिन्दूबोध
              <br />
            </h3>
            <p>
              Vishva Hindu Parishad Pradesh (transl. World Council of Hindus) is
              an Indian right-wing Hindu organisation based on Hindu
              nationalism.{" "}
            </p>
          </div>

          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-5 position-relative align-self-start"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <img
                  src="https://vhppmp.org/assets/img/about.jpg"
                  class="img-fluid"
                  alt="विश्व हिंदू परिषद"
                  title="विश्व हिंदू परिषद"
                />
              </div>
              <div
                class="col-lg-7 content"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <p>
                  The VHP was founded in 1964 by M. S. Golwalkar and S. S. Apte
                  in collaboration with Swami Chinmayananda. Its stated
                  objective is "to organise, consolidate the Hindu society and
                  to serve and protect the Hindu Dharma". It was established to
                  construct and renovate Hindu temples, and deal with matters of
                  cow slaughter and religious conversion. The VHP is a member of
                  the Sangh Parivar group, the family of Hindu nationalist
                  organisations led by the RSS.
                </p>
                <p class="fst-italic">
                  <strong>Vishva Hindu Parishad Pradesh</strong> was founded on
                  29th August 1964, on the auspicious day of{" "}
                  <strong>
                    Shri Krishna Janmashtami , with the blessings of the Saint
                    shakti of Bharat.
                  </strong>{" "}
                  The objective of the VHP is to organise- consolidate the Hindu
                  society and to serve – protect the Hindu Dharma. A strong,
                  effective, enduring and ever-increasing presence of VHP is
                  seen in lakhs of villages & towns in Bharat. With an increased
                  Hindu activity all over the world, a strong & self-confident
                  Hindu organisation is slowly taking shape.
                </p>

                <p>
                  Through over 100000 service projects in the field of health,
                  education, self- empowerment, Gram Shiksha Mandir etc. VHP is
                  strengthening the grass root level of the Hindu society.
                  Through the consistent efforts at eradication of social evils
                  like untouchability, VHP is absolving the Hindu society &
                  rejuvenating it to express it’s inherent Hindu Unity.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer id="footer" class="vh-footer dark-background">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <div class="vh-footer-link">
                <Link to="/PrivacyPolicies">
                  <span>Privacy Policies</span>
                </Link>{" "}
                |{" "}
                <Link to="/TermsConditions">
                  <span>Terms and Conditions</span>
                </Link>{" "}
                |{" "}
                <Link to="/ReturnPolicy">
                  <span>Return Policy</span>
                </Link>{" "}
                |{" "}
                <Link to="/contactUs">
                  <span>Contact us</span>
                </Link>{" "}
                |{" "}
                <Link to="/ShippingPolicy">
                  <span>Shipping Policy</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center">
          <p style={{ float: 'left'}}>
            © <span>Copyright {currentYear}</span>{" "}
            <strong class="px-1 sitename">Vishva Hindu Parishad</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            {" "}
            Designed by{" "}
            <a href="https://rameesystems.com/" target="_blank"   style={{ float: 'right'}}>
           <img src="https://vhppmp.org/img/ramee-logo.png" alt="Ramee Systems Logo"  style={{ width: '50px', margin: '-9px 0 0 15px'}} />

            </a>{" "}
          </div>
        </div>
      </footer>

      <a
        href="#"
        id="scroll-top"
        class="scroll-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </div>
  );
};

export default Homepage;
