import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import config from "../../common/Config";

const History_Subscriber = () => 
{
  const subscriberId  = useParams();
  const supplierTableRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);
  const [status, setStatus] = useState(0);
  const accessToken = localStorage.getItem("accessToken");


  const initializeSubscriberTable = () => {
    const $table = $(supplierTableRef.current);
    const searchValue = $("#searchValue").val();
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/ws_subscriber_history`,
        type: "POST",
        headers: {
          authorization: `Bearer`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue,
            status: status,
            member_id : subscriberId.subscriberId
          });
        },
        dataSrc: function (json) {
          return json.data;
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        {
          data: null,
          width: "6%",
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          data: null,
          render: function (data, type, row) {
            return `${row.fname} ${row.mname} ${row.lname}`;
          },
        },
        { data: "contact" },
        { data: "email" },
        {
          data: null,
          render: function (data, type, row) {
            if (!row.reg_date || row.reg_date === null) {
              return "N/A";
            }
            const dateParts = row.reg_date.split("-");
            const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
            return formattedDate;
          },
        },
        {
          data: null,
          render: function (data, type, row) {
            if (!row.renewal_date || row.renewal_date === null) {
              return "N/A";
            }
            const dateParts = row.renewal_date.split("-");
            const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
            return formattedDate;
          },
        },
        { data: null,
          render:function(data, type, row){
            return row.subscription_type+' '+ (row.subscription_type  === '1' ? 'year':'years');  
          }
        },
        { data: "city" },
        {
            data: null,
            render: function (data, type, row) {
              // Check if the status is active (1) and the payment status (isPaid)
              if (row.status == 1 && row.isPaid == 1) {
                return `<span style="background-color: green; color: white; padding: 5px; border-radius: 3px;">Active</span>`;
              } 
              // Check if the status is active (1) but the payment status is not paid (isPaid == 0)
              else if (row.status == 1 && row.isPaid == 0) {
                return `<span style="background-color: orange; color: white; padding: 5px; border-radius: 3px;">Pending Payment</span>`;
              } 
              // Check if the status is inactive (0)
              else if (row.status == 0) {
                return `<span style="background-color: yellow; color: black; padding: 5px; border-radius: 3px;">Inactive</span>`;
              } 
              // Check if the status is expired (2)
              else if (row.status == 2) {
                return `<span style="background-color: red; color: white; padding: 5px; border-radius: 3px;">Expired</span>`;
              }
              // Return an empty string or a default message if none of the conditions are met
              return `<span style="padding: 5px; border-radius: 3px;">Unknown</span>`;
            },
          }          
      ],
      columnDefs: [
        {
          orderable: false,
          targets: "_all",
        },
      ],

      pageLength: 10,
    });

    //   $table.on('change', '.switch-input', function() {
    //     const id = $(this).data('id');
    //     const status = this.checked ? 1 : 0;

    //     handleChangeStatus(id, status);
    // });
  };

  useEffect(() => {
    // alert(subscriberId.subscriberId);
    if (accessToken === null) {
      window.location.href = "/login";
      return;
    }
    
    initializeSubscriberTable();
    return () => {
      if (
        supplierTableRef.current &&
        $.fn.DataTable.isDataTable(supplierTableRef.current)
      ) {
        $(supplierTableRef.current).DataTable().destroy();
      }
    };
  }, [searchValue, status]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <span className="last-crumb">Subscriber History</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div
                className="show-order-area dashboard"
                style={{ position: "relative" }}
              >
                <div>
                  <div
                    className="verical-scrl-wrapper common-table supplier-table"
                    id="style-3"
                  >
                    <table
                      className="table table-bordered dataTable"
                      id="neworderTable"
                      ref={supplierTableRef}
                    >
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Email</th>
                          <th>Registration Date</th>
                          <th>Renewal Date</th>
                          <th>Subscription Plan</th>
                          <th>City</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default History_Subscriber;
