import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../Header";
import Footer from "../../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import config from "../../common/Config";
import Swal from "sweetalert2";

const Add_Subscriber_common = () => {
  const accessToken = localStorage.getItem("accessToken");
  const navigateToSupplierList = useNavigate();
  const [errors, setErrors] = useState({});
  const [paymentMethodCheque, setPaymentMethodCheque] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [subscription, setSubscription] = useState(1);
  const [payment, setPayment] = useState(3);
  const [isFree, setIsFree] = useState(0);
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);

  const [formData, setFormData] = useState({
    fname: "",
    mname: "",
    lname: "",
    address:"",
    address2:"",
    address3:"",
    email: "",
    phone: "",
    birthDate: "",
    postal_code: "",
    state: "",
    city: "",
  });
const currentYear = new Date().getFullYear();
  const validateForm = () => {
    const newErrors = {};
    // First Name validation
    if (!formData.fname) {
      newErrors.fname = " First name is Required.";
    } else if (formData.fname.length < 2) {
      newErrors.fname = "First name must consist of at least 2 characters.";
    } else if (formData.fname.length > 255) {
      newErrors.fname = "First name must not exceed 255 characters.";
    }

    //Last Name validation
    if (!formData.lname) {
      newErrors.lname = "Last name is Required.";
    } else if (formData.lname.length < 2) {
      newErrors.lname = "Last name must consist of at least 2 characters.";
    } else if (formData.lname.length > 255) {
      newErrors.lname = "Last name must not exceed 255 characters.";
    }

 
    // Birthdate validation
    if (!formData.birthDate) {
      newErrors.birthDate = "Birthdate is Required.";
    } 
  const birthDateObj = new Date(formData.birthDate);
  const today = new Date();
  
  const ageThreshold = new Date(today.setFullYear(today.getFullYear() - 18));
  
 
  if (birthDateObj <= ageThreshold) {
   
     
  } else {
      newErrors.birthDate = "You are not 18+.";
  }

     //Address Name validation
    if (!formData.address) {
      newErrors.address = "Address is Required.";
    }

    if (!formData.address2) {
      newErrors.address2 = "Address is Required.";
    }

    // if (!formData.address3) {
    //   newErrors.address3 = "Address is Required.";
    // }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is Required.";
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be between 10 and 12 digits.";
    }


    // Postal code validation
    if (!formData.postal_code) {
      newErrors.postal_code = "Postal code is Required.";

    } 

    // Finance person validation
    if (!formData.state) {
      newErrors.state = "State is required.";
    } 

    if (!formData.city) {
      newErrors.city = "City is Required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      let fieldErrors = {};
      // Validate the input field immediately as the user types
      switch (name) {
        case "fname":
          if (!value) {
            fieldErrors.fname = "First name is required..";
          } else if (value.length < 2) {
            fieldErrors.fname = "Name must consist of at least 2 characters.";
          } else if (value.length > 255) {
            fieldErrors.fname = "Name must not exceed 25 characters.";
          }
          if (!fieldErrors.fname) {
            fieldErrors.fname = "";
          }
          break;

          case "lname":
          if (!value) {
            fieldErrors.lname = "Last name is required.";
          } else if (value.length < 2) {
            fieldErrors.lname = "Name must consist of at least 2 characters.";
          } else if (value.length > 255) {
            fieldErrors.lname = "Name must not exceed 25 characters.";
          }
          if (!fieldErrors.lname) {
            fieldErrors.lname = "";
          }
          break;

        case "birthDate":
                    if (!value) {
                      fieldErrors.birthDate = "BirthDate is required.";
                    } 
            
                    if (!fieldErrors.birthDate) {
                      fieldErrors.birthDate = "";
                    }
                    break;

        case "phone":
          if (!value) {
            fieldErrors.phone = "Phone is Required.";
          } else if (!/^\d{10,12}$/.test(value)) {
            fieldErrors.phone = "Phone number must be between 10 and 12 digits.";
          }
          if (!fieldErrors.phone) {
            fieldErrors.phone = "";
          }
          break;

          case "address":
            if (!value) {
              fieldErrors.address = "Address is required.";
            } 
    
            if (!fieldErrors.address) {
              fieldErrors.address = "";
            }
            break;

            case "address2":
              if (!value) {
                fieldErrors.address2 = "Address is required.";
              } 
      
              if (!fieldErrors.address2) {
                fieldErrors.address2 = "";
              }
              break;

              // case "address3":
              //   if (!value) {
              //     fieldErrors.address3 = "Address is required.";
              //   } 
        
              //   if (!fieldErrors.address3) {
              //     fieldErrors.address3 = "";
              //   }
              //   break;


              
                
                    case "city":
                      if (!value) {
                        fieldErrors.city = "City is required.";
                      } 
              
                      if (!fieldErrors.city) {
                        fieldErrors.city = "";
                      }
                      break;

              case "state":
                if (!value) {
                  fieldErrors.state = "State is required.";
                } 
        
                if (!fieldErrors.state) {
                  fieldErrors.state = "";
                }
                break;

                case "postal_code":
                  if (!value) {
                    fieldErrors.postal_code = "Pin code is required.";
                  } 
          
                  if (!fieldErrors.postal_code) {
                    fieldErrors.postal_code = "";
                  }
                  break;                 


            default:
              break;
          }

      // Update errors state for the specific field
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...fieldErrors,
      }));
    };

  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (amount, fname, lname, email, contact, memberId) => {
    if (payment === 3) {
      try {
        // Load Razorpay script
        await loadRazorpayScript();
         const amountInPaise = amount * 100;
       // const amountInPaise = 1 * 100;
        const formData = new FormData();
        formData.set("amount", amountInPaise);
        formData.set("memberId", memberId);
        // Get the order data from your backend using Axios
        const response = await axios.post(
          `${config.API_URL}/ws_make_payment`,
          formData
        );
        const orderData = response.data;
        // Razorpay options
        const options = {
          // key: "rzp_test_pWYhgUKsZbIld5",
          key: "rzp_live_0YiizqzM2Fk8dJ",

          amount: amountInPaise, // Amount in paise
          currency: orderData.currency,
          name: fname + " " + lname,
          handler: function (response) {
            const paymentId = response.razorpay_payment_id;
            console.log(response);
            console.log("payment ID   " + paymentId);

            if (paymentId) {
              Swal.fire({
                title: "Success!",
                text: "Subscriber added successfully.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
              
              updatePaymentStatus(memberId, paymentId);
              // navigateToSupplierList(`/homepage`);
            }
          },
          modal: {
            escape: false, // Prevents closing the modal by clicking outside
          },
          prefill: {
            name: fname + " " + lname,
            email: email === '' ? '' : email,  
            contact: contact, 
        },        
        theme: {
            color: "#F37254", // Customize the theme color
          },
        };

        const rzp = new window.Razorpay(options);
        // Handle payment failure
        rzp.on("payment.failed", function (response) {
          // You can handle failure cases here (log or show a message)
          console.error("Payment failed:", response);
        });

        // Open the Razorpay payment modal
        rzp.open();
      } catch (error) {
        console.error(
          "Error loading Razorpay script or during payment process:",
          error
        );
      }
    } else {
      Swal.fire({
        title: "Success!",
        text: "Subscriber added successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      updatePaymentStatus(memberId, '000');
      // navigateToSupplierList(`/subscriber`);
    }
  };

  const submitSubscriberForm = async () => {
    if (!validateForm()) return;
    setIsSubmitBtn(true);
    const form = document.getElementById("addSubscriberform");
    const formData = new FormData(form);
    const amount = totalAmount;
    const fname = formData.get("fname");
    const lname = formData.get("lname");
    const email = formData.get("email");
    const contact = formData.get("phone");
    // console.log(payment);

    try {
      const response = await axios.post(
        `${config.API_URL}/ws_subscriber_add`,
        formData,
        {
          headers: {
            authorization: `Bearer`,
          },
        }
      );

      let member_id = null;
      const result = response.data;
      member_id = result.member_id;
      if (result.success == true) {
        handlePayment(amount, fname, lname, email, contact, member_id);
      } else {
        setIsSubmitBtn(false);
        Swal.fire({
          title: "Failed!",
          text: result.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  const FetchPrice = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/ws_get_price`);
      if (response.data.status) {
        const amount = parseFloat(response.data.data, 10);
        setPrice(amount);
        setTotalAmount(amount);
      } else {
        console.error("Error: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching price:", error);
    }
  };

  const updatePaymentStatus = async (memberId, paymentId) => {
    try {
      const formData = new FormData();
      formData.set("memberId", memberId);
      formData.set("paymentId", paymentId);
      const response = await axios.post(
        `${config.API_URL}/ws_update_payment_status`,
        formData
      );
      if (response.data.success) {
        navigateToSupplierList(`/ThankYou`);
      } else {
        console.error("Error: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching price:", error);
    }
  };

  useEffect(() => {
    FetchPrice();
  }, []);

  const calculateAmountChange = () => {
    let amount = 0;

    if (subscription != 0) {
      amount = subscription * price;
    }

    // for free payment method
    if (payment == "4") {
      setTotalAmount(0);
    } else {
      setTotalAmount(amount);
    }
  };

  const handlePaymentMethod = (value) => {
    if (value === "2") {
      setPaymentMethodCheque(1);
    } else {
      setPaymentMethodCheque(0);
    }

    if (value === "4") {
      setIsFree(1);
      setTotalAmount(0);
    }
    else {
      setIsFree(0);
      let amount = subscription * price;

      setTotalAmount(amount);
      // calculateAmountChange();
    }
  };

  useEffect(() => {
    calculateAmountChange();
  }, [subscription]);

  return (
    <div>
<header id="header" class="vh-header d-flex align-items-center sticky-top">
  <div class="container position-relative d-flex align-items-center justify-content-between"> <a href="/" class="logo d-flex align-items-center me-auto me-xl-0">
    <h1 class="sitename">
      <img src="https://vhppmp.org/assets/img/Vishva_Hindu_Parishad_Logo.png" alt="विश्व हिंदू परिषद" title="विश्व हिंदू परिषद" /></h1>
    </a>
    <nav id="navmenu" class="navmenu">
    <ul>
        <li><Link to="/Homepage"><span>Home</span></Link></li>
        <li><Link to="/Add_Subscriber_common" className="active"><span>Hindubodh</span></Link></li>
    </ul>
    <i class="mobile-nav-toggle d-xl-none bi bi-list"></i> </nav>
  </div>
</header>
    <main class="main">
      <div class="page-title">
      <div class="container d-lg-flex justify-content-between align-items-center">
        <h1 class="mb-2 mb-lg-0">Subscribe</h1>
      </div>
    </div>


    <section id="starter-section" class="starter-section section">
    <div class="main-area">
    <div class="container" data-aos="fade-up">
          <div className="row">
          <div class="subcriber-area">
            <div className="col-12 col-lg-12 col-sm-12">
              <div
                className="show-order-area dashboard"
                style={{ position: "relative" }}
              >
                  <form id="addSubscriberform">
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="fname"  class="form-label">
                            First Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="fname"
                            id="fname"
                            value={formData.fname}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-sm-12">
                          {errors.fname && (
                            <span className="text-danger">{errors.fname}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="mname"  class="form-label">
                            Middle Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="mname"
                            id="mname"
                            value={formData.mname}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="lname"  class="form-label">
                            Last Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lname"
                            id="lname"
                            value={formData.lname}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-sm-12">
                          {errors.lname && (
                            <span className="text-danger">{errors.lname}</span>
                          )}
                        </div>
                      </div>
                         <div className="col-sm-3">
                        <label htmlFor="birthDate" class="form-label">
                          Birth Date *
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="birthDate"
                          id="birthDate"
                          value={formData.birthDate}
                          onChange={handleChange}
                        />
                         {errors.birthDate && (
                            <span className="text-danger">{errors.birthDate}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="email"  class="form-label">
                            Email Address 
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="phone" class="form-label">
                            Phone Number *
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="phone"
                            id="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-sm-12">
                          {errors.phone && (
                            <span className="text-danger">{errors.phone}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address" class="form-label">
                          Address *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          id="address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                         {errors.address && (
                            <span className="text-danger">{errors.address}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address2" class="form-label">
                          Line 2 *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address2"
                          id="address2"
                          value={formData.address2}
                          onChange={handleChange}
                        />
                         {errors.address2 && (
                            <span className="text-danger">{errors.address2}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address3" class="form-label">
                          Line 3 
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address3"
                          id="address3"
                          value={formData.address3}
                          onChange={handleChange}
                        />
                        {errors.address3 && (
                            <span className="text-danger">{errors.address3}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="city" class="form-label">
                          Town / City / Village *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          id="city"
                          value={formData.city}
                          onChange={handleChange}
                        />
                        {errors.city && (
                            <span className="text-danger">{errors.city}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="state" class="form-label">
                          State * 
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="state"
                          id="state"
                          value={formData.state}
                          onChange={handleChange}
                        />
                         {errors.state && (
                            <span className="text-danger">{errors.state}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="postal_code" class="form-label">
                          Pin Code *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="postal_code"
                          id="postal_code"
                          value={formData.postal_code}
                          onChange={handleChange}
                        />
                        {errors.postal_code && (
                            <span className="text-danger">{errors.postal_code}</span>
                          )}
                      </div>
                    </div>
                   
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label
                          htmlFor="subscriber_plan"
                          class="form-label">
                          Subscription *
                        </label>
                        <select
                          name="subscriber_plan"
                          value={subscription}
                          className="form-select orgselect"
                          onChange={(e) =>
                            setSubscription(parseInt(e.target.value))
                          }>
                          <option value="0" disabled>Select Plan</option>
                          <option value="1">1 Year</option>
                          {/*<option value="2">2 Years</option>*/}
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="amount" class="form-label">
                          Amount
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="amount"
                          id="amount"
                          value={totalAmount}
                          readOnly
                        />
                      </div>

                       {/*<div className="col-sm-3">
                        <label
                          htmlFor="payment_method"
                          class="form-label">
                          Payment Method *
                        </label>
                        <select
                          name="payment_method"
                          value={3}
                          onChange={(e) => {
                            setPayment(e.target.value);
                            handlePaymentMethod(e.target.value);
                          }}
                          className="form-select orgselect"
                        >
                          <option value="0" disabled>Select Method</option>
                          <option value="3">Online</option>
                        </select>
                      </div>*/}
                      {paymentMethodCheque == 1 && (
                        <>
                          <div className="col-sm-3">
                            <label
                              htmlFor="chequeDate"
                              className="col-form-label"
                            >
                              Cheque Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="chequeDate"
                              id="chequeDate"
                            />
                          </div>
                          <div className="col-sm-3">
                            <label
                              htmlFor="chequeNumber"
                              className="col-form-label"
                            >
                              Cheque Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="chequeNumber"
                              id="chequeNumber"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <hr/>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-12 col-lg-12 col-sm-12">
                        <button
                          onClick={submitSubscriberForm}
                          type="button"
                          class="subcriber-btn"
                          disabled={isSubmitBtn}
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </form>     
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
    </section>
    </main>
    <footer id="footer" class="vh-footer dark-background">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <div class="vh-footer-link">
                <Link to="/PrivacyPolicies">
                  <span>Privacy Policies</span>
                </Link>{" "}
                |{" "}
                <Link to="/TermsConditions">
                  <span>Terms and Conditions</span>
                </Link>{" "}
                |{" "}
                <Link to="/ReturnPolicy">
                  <span>Return Policy</span>
                </Link>{" "}
                |{" "}
                <Link to="/contactUs">
                  <span>Contact us</span>
                </Link>{" "}
                |{" "}
                <Link to="/ShippingPolicy">
                  <span>Shipping Policy</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center">
          <p style={{ float: 'left'}}>
            © <span>Copyright {currentYear}</span>{" "}
            <strong class="px-1 sitename">Vishva Hindu Parishad</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            {" "}
            Designed by{" "}
            <a href="https://rameesystems.com/" target="_blank"   style={{ float: 'right'}}>
           <img src="https://vhppmp.org/img/ramee-logo.png" alt="Ramee Systems Logo"  style={{ width: '50px', margin: '-9px 0 0 15px'}} />

            </a>{" "}
          </div>
        </div>
      </footer>

<a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> 
{/* <div id="preloader"></div> */}
</div>

  
  );
};

export default Add_Subscriber_common;
