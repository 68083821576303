import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import config from "../common/Config";

const ReturnPolicy = () => {
const currentYear = new Date().getFullYear();
return (
<div>
    <header id="header" class="vh-header d-flex align-items-center sticky-top">
  <div class="container position-relative d-flex align-items-center justify-content-between"> 
    <Link to="/" class="logo d-flex align-items-center me-auto me-xl-0">
    <h1 class="sitename">
      <img src="https://vhppmp.org/assets/img/Vishva_Hindu_Parishad_Logo.png" alt="विश्व हिंदू परिषद" title="विश्व हिंदू परिषद" /></h1>
    </Link>
    <nav id="navmenu" class="navmenu">
    <ul>
        <li><Link to="/Homepage" className="active"><span>Home</span></Link></li>
		<li><Link to="/Add_Subscriber_common"><span>Hindubodh</span></Link></li>
      </ul>
      <i class="mobile-nav-toggle d-xl-none bi bi-list"></i> </nav>
  </div>
</header>


<main class="main">

   
    <div class="page-title">
      <div class="container d-lg-flex justify-content-between align-items-center">
        <h1 class="mb-2 mb-lg-0">Return Policy</h1>
      </div>
    </div>

    <section id="starter-section" class="starter-section section">

      
      {/* <div class="container section-title" data-aos="fade-up">
        <div class="section-title-container d-flex align-items-center justify-content-between">
			
        <h2>Refund and Cancellation Policy</h2>
         
        </div>
      </div> */}
      <div class="main-area">
      <div class="container" data-aos="fade-up">
        <p>Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will refund back the money, provided the reasons are genuine and proved after investigation. Please read the fine prints of each deal before buying it, it provides all the details about the services or the product you purchase.

In case of dissatisfaction from our services, clients have the liberty to cancel their projects and request a refund from us. Our Policy for the cancellation and refund will be as follows:

</p>
		  
		  <h3>Cancellation Policy</h3>
		  <p>For Cancellations please contact the us via contact us link. <br/>

Requests received later than seven business days prior to the end of the current service period will be treated as cancellation of services for the next service period.

</p>
		  
		  <h3>Refund Policy</h3>
		  <p>We will try our best to create the suitable design concepts for our clients.<br/>

In case any client is not completely satisfied with our products we can provide a refund, subject to condition is that the same should within 15 days of issue of card. 

If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payment gateway name payments refund will be made to the same account.
</p>
		  
      </div>
</div>
    </section>

  </main>


  <footer id="footer" class="vh-footer dark-background">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <div class="vh-footer-link">
                <Link to="/PrivacyPolicies">
                  <span>Privacy Policies</span>
                </Link>{" "}
                |{" "}
                <Link to="/TermsConditions">
                  <span>Terms and Conditions</span>
                </Link>{" "}
                |{" "}
                <Link to="/ReturnPolicy">
                  <span>Return Policy</span>
                </Link>{" "}
                |{" "}
                <Link to="/contactUs">
                  <span>Contact us</span>
                </Link>{" "}
                |{" "}
                <Link to="/ShippingPolicy">
                  <span>Shipping Policy</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center">
          <p style={{ float: 'left'}}>
            © <span>Copyright {currentYear}</span>{" "}
            <strong class="px-1 sitename">Vishva Hindu Parishad</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            {" "}
            Designed by{" "}
            <a href="https://rameesystems.com/" target="_blank"   style={{ float: 'right'}}>
           <img src="https://vhppmp.org/img/ramee-logo.png" alt="Ramee Systems Logo"  style={{ width: '50px', margin: '-9px 0 0 15px'}} />

            </a>{" "}
          </div>
        </div>
      </footer>

<a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> 
{/* <div id="preloader"></div> */}
</div>




  );
};

export default ReturnPolicy;
