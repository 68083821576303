import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../Header";
import Footer from "../../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import config from "../../common/Config";
import Swal from "sweetalert2";

const Add_Subscriber = () => {
  const accessToken = localStorage.getItem("accessToken");
  const navigateToSupplierList = useNavigate();
  const [errors, setErrors] = useState({});
  const [paymentMethodCheque, setPaymentMethodCheque] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [subscription, setSubscription] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [payment, setPayment] = useState(0);
  const [isFree, setIsFree] = useState(0);
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);

  const [formData, setFormData] = useState({
    fname: "",
    mname: "",
    lname: "",
    address:"",
    address2:"",
    address3:"",
    email: "",
    phone: "",
    birthDate: "",
    postal_code: "",
    state: "",
    city: "",
  });

  const validateForm = () => {
    const newErrors = {};
    // First Name validation
    if (!formData.fname) {
      newErrors.fname = " First name is Required.";
    } else if (formData.fname.length < 2) {
      newErrors.fname = "First name must consist of at least 2 characters.";
    } else if (formData.fname.length > 255) {
      newErrors.fname = "First name must not exceed 255 characters.";
    }

    //Last Name validation
    if (!formData.lname) {
      newErrors.lname = "Last name is Required.";
    } else if (formData.lname.length < 2) {
      newErrors.lname = "Last name must consist of at least 2 characters.";
    } else if (formData.lname.length > 255) {
      newErrors.lname = "Last name must not exceed 255 characters.";
    }
     // Birthdate validation
    if (!formData.birthDate) {
      newErrors.birthDate = "Birthdate is Required.";
    } 
     if (formData.birthDate) {
    const birthDateObj = new Date(formData.birthDate);
    const today = new Date();

      const ageThreshold = new Date(today.setFullYear(today.getFullYear() - 18));
      if (birthDateObj <= ageThreshold) {

      } else {
      newErrors.birthDate = "You are not 18+.";
      }
    }
     //Address Name validation
    if (!formData.address) {
      newErrors.address = "Address is Required.";
    }

    if (!formData.address2) {
      newErrors.address2 = "Address is Required.";
    }

    // if (!formData.address3) {
    //   newErrors.address3 = "Address is Required.";
    // }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is Required.";
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be between 10 and 12 digits.";
    }

   
    // Postal code validation
    if (!formData.postal_code) {
      newErrors.postal_code = "Postal code is Required.";
    } 

    // Finance person validation
    if (!formData.state) {
      newErrors.state = "State is required.";
    } 

    if (!formData.city) {
      newErrors.city = "City is Required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      let fieldErrors = {};
      switch (name) {
        case "fname":
          if (!value) {
            fieldErrors.fname = "First name is required..";
          } else if (value.length < 2) {
            fieldErrors.fname = "Name must consist of at least 2 characters.";
          } else if (value.length > 255) {
            fieldErrors.fname = "Name must not exceed 25 characters.";
          }
          if (!fieldErrors.fname) {
            fieldErrors.fname = "";
          }
          break;

          case "lname":
          if (!value) {
            fieldErrors.lname = "Last name is required.";
          } else if (value.length < 2) {
            fieldErrors.lname = "Name must consist of at least 2 characters.";
          } else if (value.length > 255) {
            fieldErrors.lname = "Name must not exceed 25 characters.";
          }
          if (!fieldErrors.lname) {
            fieldErrors.lname = "";
          }
          break;
        case "birthDate":
          if (!value) {
            fieldErrors.birthDate = "BirthDate is required.";
          } 

          if (!fieldErrors.birthDate) {
            fieldErrors.birthDate = "";
          }
          break;
         
        case "phone":
          if (!value) {
            fieldErrors.phone = "Phone is Required.";
          } else if (!/^\d{10,12}$/.test(value)) {
            fieldErrors.phone = "Phone number must be between 10 and 12 digits.";
          }
          if (!fieldErrors.phone) {
            fieldErrors.phone = "";
          }
          break;

          case "address":
            if (!value) {
              fieldErrors.address = "Address is required.";
            } 
    
            if (!fieldErrors.address) {
              fieldErrors.address = "";
            }
            break;

            case "address2":
              if (!value) {
                fieldErrors.address2 = "Address is required.";
              } 
      
              if (!fieldErrors.address2) {
                fieldErrors.address2 = "";
              }
              break;

              // case "address3":
              //   if (!value) {
              //     fieldErrors.address3 = "Address is required.";
              //   } 
        
              //   if (!fieldErrors.address3) {
              //     fieldErrors.address3 = "";
              //   }
              //   break;

                case "city":
                if (!value) {
                  fieldErrors.city = "City is required.";
                } 
        
                if (!fieldErrors.city) {
                  fieldErrors.city = "";
                }
                break;

                case "state":
                  if (!value) {
                    fieldErrors.state = "State is required.";
                  } 
          
                  if (!fieldErrors.state) {
                    fieldErrors.state = "";
                  }
                  break;

                  case "postal_code":
                  if (!value) {
                    fieldErrors.postal_code = "State is required.";
                  } 
          
                  if (!fieldErrors.postal_code) {
                    fieldErrors.postal_code = "";
                  }
                  break;



            default:
              break;
          }

      // Update errors state for the specific field
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...fieldErrors,
      }));
    };

  {
    /* <script src="https://checkout.razorpay.com/v1/checkout.js"></script> */
  }

    const loadRazorpayScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.onload = () => resolve();
        script.onerror = (error) => reject(error);
        document.body.appendChild(script);
      });
    };
  

  const handlePayment = async (amount, fname, lname, email, contact, memberId) => {
    if (payment === '3') {
      try {
        // Load Razorpay script
        await loadRazorpayScript();
  
        // Convert amount to paise (1 INR = 100 paise)
        const amountInPaise = amount * 100;  // Or use 1 * 100 for testing
        
        const formData = new FormData();
        formData.set("amount", amountInPaise);
        formData.set("memberId", memberId);  // Send memberId with the order request
  
        // Get the order data from the backend using Axios
        const response = await axios.post(
          `${config.API_URL}/ws_make_payment`, // Make sure this is correct URL
          formData
        );
        const orderData = response.data; // Order data from backend
        const memberIdFromResponse = orderData.memberId; // Retrieve memberId from response
  
        // Razorpay options
        const options = {
          key: "rzp_live_0YiizqzM2Fk8dJ",  // Your Razorpay key
          amount: amountInPaise,  // Amount in paise
          currency: orderData.currency,  // Currency from backend
          name: fname + " " + lname,  // User name
          handler: function (response) {
            const paymentId = response.razorpay_payment_id;
            // console.log(response);
            // console.log("Payment ID: " + paymentId);
  
            if (paymentId) {
              Swal.fire({
                title: "Success!",
                text: "Subscriber added successfully.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
  
              // Pass memberId with paymentId to update the status
              updatePaymentStatus(memberIdFromResponse, paymentId);  // Use memberId from response
            }
          },
          modal: {
            escape: false, // Prevent closing modal by clicking outside
          },
          prefill: {
            name: fname + " " + lname,
            email: email === '' ? '' : email,  
            contact: contact, 
          },
          theme: {
            color: "#F37254", // Customize theme color
          },
        };
  
        const rzp = new window.Razorpay(options);
  
        // Handle payment failure
        rzp.on("payment.failed", function (response) {
          // Handle failure (log or show a message)
          console.error("Payment failed:", response);
        });
  
        // Open Razorpay payment modal
        rzp.open();
      } catch (error) {
        console.error(
          "Error loading Razorpay script or during payment process:",
          error
        );
      }
    } else {
      Swal.fire({
        title: "Success!",
        text: "Subscriber added successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      updatePaymentStatus(memberId, '000');  // Update payment status without actual payment
    }
  };

  const submitSubscriberForm = async () => {
    if (!validateForm()) return;
    setIsSubmitBtn(true);
    const form = document.getElementById("addSubscriberform");
    const formData = new FormData(form);
    const amount = totalAmount;
    const fname = formData.get("fname");
    const lname = formData.get("lname");
    const email = formData.get("email");
    const contact = formData.get("phone");
    // console.log(payment);

    try {
      const response = await axios.post(
        `${config.API_URL}/ws_subscriber_add`,
        formData,
        {
          headers: {
            authorization: `Bearer`,
          },
        }
      );

      let member_id = null;
      const result = response.data;
      member_id = result.member_id;
      if (result.success == true) {
        handlePayment(amount, fname, lname, email, contact, member_id);
        // navigateToSupplierList(`/subscriber`);
      } else {
        setIsSubmitBtn(false);
        Swal.fire({
          title: "Failed!",
          text: result.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  const FetchPrice = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/ws_get_price`);
      if (response.data.status) {
        const amount = parseFloat(response.data.data, 10);
        setPrice(amount);
        setTotalAmount(amount);
      } else {
        console.error("Error: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching price:", error);
    }
  }; 

  const updatePaymentStatus = async (memberId, paymentId) => {
    try {
      const formData = new FormData();
      formData.set("memberId", memberId);
      formData.set("paymentId", paymentId);
      const response = await axios.post(
        `${config.API_URL}/ws_update_payment_status`,
        formData
      );
      // console.log(response.data);
      if (response.data.success) {
        navigateToSupplierList(`/subscriber`);
      } else {
        console.error("Error: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching price:", error);
    }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = '/login';
      return;
    }
    FetchPrice();
  }, []);

  const calculateAmountChange = () => {
    let amount = 0;

    if (subscription != 0) {
      amount = subscription * price;
    }

    if (discount > 0) {
      let percentage = (amount * discount) / 100;
      amount = amount - percentage;
    }

    // for free payment method
    if (payment == "4") {
      setTotalAmount(0);
    } else {
      setTotalAmount(amount);
    }
  };

  const handlePaymentMethod = (value) => {
    if (value === "2") {
      setPaymentMethodCheque(1);
    } else {
      setPaymentMethodCheque(0);
    }

    if (value === "4") {
      setIsFree(1);
      setTotalAmount(0);
      setDiscount(0);
    }
    // else if(value === '3')
    // {

    // }
    else {
      setIsFree(0);
      let amount = subscription * price;
      if (discount > 0) {
        let percentage = (amount * discount) / 100;
        amount = amount - percentage;
      }

      setTotalAmount(amount);
      // calculateAmountChange();
    }
  };

  useEffect(() => {
    calculateAmountChange();
  }, [discount, subscription]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              {" "}
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <span className="last-crumb">Add Subscriber</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div
                className="show-order-area dashboard"
                style={{ position: "relative" }}
              >
                <div className="add-supplier-popup">
                  <form id="addSubscriberform">
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="fname" className="col-form-label">
                            First Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="fname"
                            id="fname"
                            value={formData.fname}
                            onChange={handleChange}
                          />
                            {errors.fname && (
                            <span className="text-danger">{errors.fname}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="mname" className="col-form-label">
                            Middle Name 
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="mname"
                            id="mname"
                            value={formData.mname}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="lname" className="col-form-label">
                            Last Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lname"
                            id="lname"
                            value={formData.lname}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-sm-12">
                          {errors.lname && (
                            <span className="text-danger">{errors.lname}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="birthDate" className="col-form-label">
                          Birth Date *
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="birthDate"
                          id="birthDate"
                          value={formData.birthDate}
                          onChange={handleChange}
                        />
                         {errors.birthDate && (
                            <span className="text-danger">{errors.birthDate}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="email" className="col-form-label">
                            Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="col-sm-12">
                          <label htmlFor="phone" className="col-form-label">
                            Phone Number *
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="phone"
                            id="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                           {errors.phone && (
                            <span className="text-danger">{errors.phone}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address" className="col-form-label">
                          Address *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          id="address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                         {errors.address && (
                            <span className="text-danger">{errors.address}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address2" className="col-form-label">
                          Line 2 *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address2"
                          id="address2"
                          value={formData.address2}
                          onChange={handleChange}
                        />
                         {errors.address2 && (
                            <span className="text-danger">{errors.address2}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address3" className="col-form-label">
                          Line 3 
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address3"
                          id="address3"
                          value={formData.address3}
                          onChange={handleChange}
                        />
                         {errors.address3 && (
                            <span className="text-danger">{errors.address3}</span>
                          )}
                      </div>
                   
                      <div className="col-sm-3">
                        <label htmlFor="city" className="col-form-label">
                        Town / City / Village *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          id="city"
                          value={formData.city}
                          onChange={handleChange}
                        />
                         {errors.city && (
                            <span className="text-danger">{errors.city}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="state" className="col-form-label">
                          State *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="state"
                          id="state"
                          value={formData.state}
                          onChange={handleChange}
                        />
                         {errors.state && (
                            <span className="text-danger">{errors.state}</span>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="postal_code" className="col-form-label">
                          Pin Code *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="postal_code"
                          id="postal_code"
                          value={formData.postal_code}
                          onChange={handleChange}
                        />
                         {errors.postal_code && (
                            <span className="text-danger">{errors.postal_code}</span>
                          )}
                      </div>
                      
                    </div>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label
                          htmlFor="subscriber_plan"
                          className="col-form-label"
                        >
                          Subscription *
                        </label>
                        <select
                          name="subscriber_plan"
                          value={subscription}
                          className="form-select orgselect"
                          onChange={(e) =>
                            setSubscription(parseInt(e.target.value))
                          }
                        >
                          <option value="0" disabled>Select</option>
                          <option value="1">1 Year</option>
                          {/*<option value="2">2 Years</option>*/}
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="discount" className="col-form-label">
                          Discount 
                        </label>
                        <select
                          name="discount"
                          className="form-select orgselect"
                          value={discount}
                          onChange={(e) =>
                            setDiscount(parseInt(e.target.value))
                          }
                          disabled={isFree === 1}
                          defaultValue={0}
                        >
                          <option value="0" >Select Discount</option>
                          <option value="10">10 %</option>
                          <option value="50">50 %</option>
                          <option value="100">100 %</option>
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="amount" className="col-form-label">
                          Amount 
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="amount"
                          id="amount"
                          value={totalAmount}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label
                          htmlFor="payment_method"
                          className="col-form-label"
                        >
                          Payment Method *
                        </label>
                        <select
                          name="payment_method"
                          value={payment}
                          onChange={(e) => {
                            setPayment(e.target.value);
                            handlePaymentMethod(e.target.value);
                          }}
                          className="form-select orgselect"
                        >
                          <option value="0" disabled>Select Method</option>
                          <option value="1">Cash</option>
                          <option value="2">Cheque</option>
                          <option value="3">Online</option>
                          <option value="4">Free</option>
                        </select>
                      </div>
                      {paymentMethodCheque == 1 && (
                        <>
                          <div className="col-sm-3">
                            <label
                              htmlFor="chequeDate"
                              className="col-form-label"
                            >
                              Cheque Date *
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="chequeDate"
                              id="chequeDate"
                            />
                          </div>
                          <div className="col-sm-3">
                            <label
                              htmlFor="chequeNumber"
                              className="col-form-label"
                            >
                              Cheque Number *
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="chequeNumber"
                              id="chequeNumber"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-12 col-lg-12 col-sm-12">
                        <button
                          onClick={submitSubscriberForm}
                          type="button"
                          className="float-right-btn common-popup-btn"
                          disabled={isSubmitBtn}
                        >
                          Add Subscriber
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Add_Subscriber;
