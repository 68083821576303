import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL, BASE_URL } from './Config';

const Header = () => {
    const [activeLink, setActiveLink] = useState('Dashboard');
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [SiteName, setSiteName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve the logo URL, first name, and last name from localStorage
        const site_name = localStorage.getItem('site_name');
        const storedFirstName = localStorage.getItem('first_name');
        const storedLastName = localStorage.getItem('last_name');

        if (site_name) {
            setSiteName(site_name);
        }
        if (storedFirstName) {
            setFirstName(storedFirstName);
        }
        if (storedLastName) {
            setLastName(storedLastName);
        }
    }, []); 

    const handleSetActiveLink = (link) => {
        setActiveLink(link);
    };

    const handleMobileNavToggle = () => {
        setMobileNavOpen(!mobileNavOpen);
    };

    const handleLogOut = () => {
        localStorage.removeItem('accessToken');                 
        window.location.href = `${BASE_URL}/login`;
    };    

    return (
        <header id="header" className="header fixed-top" data-scrollto-offset="0">
            <div className="container-fluid d-flex align-items-center justify-content-between">
              
                <Link
                    to="/"
                    class="logo d-flex align-items-center me-auto me-xl-0"
                >
                    <h1 class="sitename">
                    <img
                        src="https://vhppmp.org/assets/img/Vishva_Hindu_Parishad_Logo.png"
                        alt="विश्व हिंदू परिषद"
                        title="विश्व हिंदू परिषद"
                    />
                    </h1>
                </Link>

                <nav id="navbar" className={`navbar ${mobileNavOpen ? 'mobile-nav-active' : ''}`}>
                    <ul className={`nav-menu ${mobileNavOpen ? 'mobile-nav-show' : ''}`}>
                        <li>
                            <Link
                                to="/Dashboard"
                                onClick={() => handleSetActiveLink('Dashboard')}
                            >
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link  to="/subscriber" >
                                <span>Subscriber List</span>
                            </Link>
                        </li>
                        <li>
                            <Link  to="/subscriber/bulk_email" >
                                <span>Bulk Email</span>
                            </Link>
                        </li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle}></i>
                </nav>
                <div className="header-nav ms-auto userprofile-area">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src="/assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" />
                                <span className="d-none d-md-block dropdown-toggle ps-2">Super Admin</span>
                            </a>
                            <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${mobileNavOpen ? 'mobile-nav-show' : ''}`}>
                                {/* <li className="dropdown-header">
                                    <h6>Super Admin</h6>
                                </li> */}
                                <li><hr className="dropdown-divider" /></li>
                                {/* <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-person"></i> <span>My Profile</span></Link></li>
                                <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-gear"></i> <span>Account Settings</span></Link></li>
                                <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-question-circle"></i> <span>Need Help?</span></Link></li> */}
                                <li><Link className="dropdown-item d-flex align-items-center last-li" to='#' onClick={() => { handleLogOut() }}><i className="bi bi-box-arrow-right"></i><span>Sign Out</span></Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;
