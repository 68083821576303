import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import config from "../common/Config";

const Subscribe = () => {
  

  return (

<div>
    <header id="header" class="vh-header d-flex align-items-center sticky-top">
  <div class="container position-relative d-flex align-items-center justify-content-between"> 
    <Link to="/" class="logo d-flex align-items-center me-auto me-xl-0">
    <h1 class="sitename">
      <img src="https://vhppmp.org/assets/img/Vishva_Hindu_Parishad_Logo.png" alt="विश्व हिंदू परिषद" title="विश्व हिंदू परिषद" /></h1>
    </Link>
    <nav id="navmenu" class="navmenu">
    <ul>
        <li><Link to="/Homepage"><span>Home</span></Link></li>
		<li><Link to="/Add_Subscriber_common" className="active"><span>Subscribe</span></Link></li>
      </ul>
      <i class="mobile-nav-toggle d-xl-none bi bi-list"></i> </nav>
  </div>
</header>


<main class="main">
    <div class="page-title">
      <div class="container d-lg-flex justify-content-between align-items-center">
        <h1 class="mb-2 mb-lg-0">Subscribe</h1>
        {/* <nav class="breadcrumb">
          <ol>
            <li><Link to="/Homepage">Home</Link></li>
            <li class="current">Subscribe</li>
          </ol>
        </nav> */}
      </div>
    </div>

    <section id="starter-section" class="starter-section section">
    <div class="main-area">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="subcriber-area">
            <div class="card-body">
              <form class="g-3">
                <div class="row">
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputCity" class="form-label">First Name</label>
                    <input type="text" class="form-control" name="fname" id="fname" required />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputState" class="form-label">Middle Name</label>
                    <input type="text" class="form-control" name="mname" id="mname" />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputZip" class="form-label">Last Name <span class="red">*</span></label>
                    <input type="text" class="form-control" name="lname" id="lname" required />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputZip" class="form-label">Email Address <span class="red">*</span></label>
                    <input type="email" class="form-control" name="email" id="email" required />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputCity" class="form-label">Phone Number <span class="red">*</span></label>
                    <input type="number" class="form-control" name="phone" id="phone" required />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputState" class="form-label">Address</label>
                    <input type="text" class="form-control" name="address" id="address" />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputZip" class="form-label">Line 2</label>
                    <input type="text" class="form-control" name="address2" id="address2" />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputZip" class="form-label">Line 3</label>
                    <input type="text" class="form-control" name="address3" id="address3" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputCity" class="form-label">City</label>
                    <input type="text" class="form-control" name="city" id="city" />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputState" class="form-label">State</label>
                    <input type="text" class="form-control" name="state" id="state" />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputZip" class="form-label">Pin Code</label>
                    <input type="text" class="form-control" name="postal_code" id="postal_code" />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputZip" class="form-label">Birth Date <span class="red">*</span></label>
                    <input type="date" class="form-control" name="birthDate" id="birthDate" required />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputCity" class="form-label">Subscription</label>
                    <select name="subscriber_plan" class="form-select orgselect">
                      <option value="0">Select</option>
                      <option value="1">1 Year</option>
                      <option value="2">2 Years</option>
                      <option value="3">3 Years</option>
                      <option value="4">4 Years</option>
                      <option value="5">5 Years</option>
                      <option value="6">6 Years</option>
                      <option value="7">7 Years</option>
                      <option value="8">8 Years</option>
                      <option value="9">9 Years</option>
                      <option value="10">10 Years</option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputState" class="form-label">Discount</label>
                    <select name="discount" class="form-select orgselect" id="discount">
                      <option value="0">Select Discount</option>
                      <option value="10">10 %</option>
                      <option value="50">50 %</option>
                      <option value="100">100 %</option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputZip" class="form-label">Amount</label>
                    <input type="text" class="form-control" name="amount" id="amount" readonly />
                  </div>
                  <div class="col-12 col-lg-3 col-sm-6">
                    <label for="inputZip" class="form-label">Payment Method</label>
                    <select name="payment_method" class="form-select orgselect" id="payment_method">
                      <option value="0">Select Method</option>
                      <option value="1">Cash</option>
                      <option value="2">Cheque</option>
                      <option value="3">Online</option>
                      <option value="4">Free</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div id="chequeFields" style={{display: 'none'}}>
                    <div class="col-12 col-lg-3 col-sm-6">
                      <label for="chequeDate" class="col-form-label">Cheque Date</label>
                      <input type="date" class="form-control" name="chequeDate" id="chequeDate" />
                    </div>
                    <div class="col-12 col-lg-3 col-sm-6">
                      <label for="chequeNumber" class="col-form-label">Cheque Number</label>
                      <input type="text" class="form-control" name="chequeNumber" id="chequeNumber" />
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row padd-l-13px">
                  <div class="col-12">
                    <button type="submit" class="subcriber-btn">Add Subscriber</button>
                  </div>
                </div>
              </form>
             
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  </main>


  <footer id="footer" class="vh-footer dark-background">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-12 col-sm-12">
        <div class="vh-footer-link"> 

        <Link to="/PrivacyPolicies"><span>Privacy Policies</span></Link> | <Link to="/TermsConditions"><span>Terms and Conditions</span></Link> | <Link to="/ReturnPolicy"><span>Return Policy</span></Link> | <Link to="/contactUs"><span>Contact us</span></Link> | <Link to="/ShippingPolicy"><span>Shipping Policy</span></Link></div>
      
      
      </div>
    </div>
  </div>
  <div class="container copyright text-center">
    <p>© <span>Copyright</span> <strong class="px-1 sitename">Vishva Hindu Parishad</strong> <span>All Rights Reserved</span></p>
    <div class="credits"> Designed by <a href="https://rameesystems.com/" target="_blank">Ramee Systems</a> </div>
  </div>
</footer>

<a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> 
{/* <div id="preloader"></div> */}
</div>




  );
};

export default Subscribe;
