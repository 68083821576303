import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const Add = () => {
  const [errors, setErrors] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [subscription, setSubscription] = useState(1); 
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);

  const [formData, setFormData] = useState({
    company: '',
    name: '',
    email: '',
    phone: '',
    contact_person: '',
    contact_person_mob: '',
    contact_person_email: '',
    finance_person: '',
    finance_person_mob : '',
  });

  const validateForm = () => {
    const newErrors = {};
    // Company validation
    if (!formData.company) {
      newErrors.company = "Mention company Name.";
    } else if (formData.company.length < 2) {
      newErrors.company = "Company name must consist of at least 2 characters.";
    } else if (formData.company.length > 255) {
      newErrors.company = "Company name must not exceed 255 characters.";
    }

    // Name validation
    if (!formData.name) {
      newErrors.name = "Mention Name.";
    } else if (formData.name.length < 2) {
      newErrors.name = "Name must consist of at least 2 characters.";
    } else if (formData.name.length > 255) {
      newErrors.name = "Name must not exceed 255 characters.";
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is Required.";
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be between 10 and 12 digits.";
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Mention Email Id.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email.";
    }

    // Contact person validation
    if (!formData.contact_person) {
      newErrors.contact_person = "Mention person Name.";
    } else if (formData.contact_person.length < 2) {
      newErrors.contact_person = "Contact person name must consist of at least 2 characters.";
    } else if (formData.contact_person.length > 255) {
      newErrors.contact_person = "Contact person name must not exceed 255 characters.";
    }

    // Contact person mobile validation
    if (!formData.contact_person_mob) {
      newErrors.contact_person_mob = "Contact person mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.contact_person_mob)) {
      newErrors.contact_person_mob = "Contact person mobile must be between 10 and 12 digits.";
    }

    // Contact person email validation
    if (!formData.contact_person_email) {
      newErrors.contact_person_email = "Mention Contact Person Email.";
    } else if (!/\S+@\S+\.\S+/.test(formData.contact_person_email)) {
      newErrors.contact_person_email = "Please enter a valid contact person email.";
    }

    // Finance person validation
    if (!formData.finance_person) {
      newErrors.finance_person = "Mention Finance Person Name.";
    } else if (formData.finance_person.length < 2) {
      newErrors.finance_person = "Finance person name must consist of at least 2 characters.";
    } else if (formData.finance_person.length > 255) {
      newErrors.finance_person = "Finance person name must not exceed 255 characters.";
    }

    
    if (!formData.finance_person_mob) {
      newErrors.finance_person_mob = "finance mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.finance_person_mob)) {
      newErrors.finance_person_mob = "finance person mobile must be between 10 and 12 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    let fieldErrors = {};
    // Validate the input field immediately as the user types
    switch (name) {
      case 'company':
        if (!value) {
          fieldErrors.company = "Mention company Name.";
        } else if (value.length < 2) {

          fieldErrors.company = "Company name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.company = "Company name must not exceed 255 characters.";
        }
         
        // Clear error if all conditions are met
        if (!fieldErrors.company) {
          //delete fieldErrors.company;
           fieldErrors.company = '';
        }
        break;

      case 'name':
        if (!value) {
          fieldErrors.name = "Mention Name.";
        } else if (value.length < 2) {
          fieldErrors.name = "Name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.name = "Name must not exceed 25 characters.";
        }
        if (!fieldErrors.name) {
          fieldErrors.name = '';
        }
        break;

      case 'phone':
        if (!value) {
          fieldErrors.phone = "Phone is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.phone = "Phone number must be between 10 and 12 digits.";
        }
        if (!fieldErrors.phone) {
          
          fieldErrors.phone = '';
        }
        break;

      case 'email':
        if (!value) {
          fieldErrors.email = "Mention Email Id.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.email = "Please enter a valid email.";
        }
        if (!fieldErrors.email) {
          fieldErrors.email = '';
        }
        break;

      case 'contact_person':
        if (!value) {
          fieldErrors.contact_person = "Mention person Name.";
        } else if (value.length < 2) {
          fieldErrors.contact_person = "Contact person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.contact_person = "Contact person name must not exceed 255 characters.";
        }
        if (!fieldErrors.contact_person) {
          fieldErrors.contact_person = '';
        }
        break;

      case 'contact_person_mob':
        if (!value) {
          fieldErrors.contact_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.contact_person_mob = "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.contact_person_mob) {
          fieldErrors.contact_person_mob = '';
        }
        break;

      case 'contact_person_email':
        if (!value) {
          fieldErrors.contact_person_email = "Mention Contact Person Email.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.contact_person_email = "Please enter a valid contact person email.";
        }
        if (!fieldErrors.contact_person_email) {
          fieldErrors.contact_person_email = '';
        }
        break;

      case 'finance_person':
        if (!value) {
          fieldErrors.finance_person = "Mention Finance Person Name.";
        } else if (value.length < 2) {
          fieldErrors.finance_person = "Finance person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.finance_person = "Finance person name must not exceed 255 characters.";
        }
        if (!fieldErrors.finance_person) {
          fieldErrors.finance_person = '';
        }
        break;

      case 'finance_person_mob':
        if (!value) {
          fieldErrors.finance_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.finance_person_mob = "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.finance_person_mob) {
          fieldErrors.finance_person_mob = '';
        }
        break;

      default:
        break;
    }

    // Update errors state for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors
    }));
  };

<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
  const handlePayment = async () => {
    const amount = 1; 
    const formData = new FormData();
    formData.set('amount', amount);

    const response = await fetch(`${config.API_URL}/ws_make_payment`, formData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    
    const orderData = await response.json();

    const options = {
        key: 'rzp_live_QdbzdiIMkeLBzL',
        amount: orderData.amount,
        currency: orderData.currency,
        name: 'ABC',
        description: 'Payment Description',
        order_id: orderData.id, 
        handler: function (response) {
            console.log(response);
        },
        prefill: {
            name: 'Customer Name',
            email: 'customer@example.com',
            contact: '9890122659',
        },
        theme: {
            color: '#F37254',
        },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
};


  const submitSubscriberForm = async () => {
    // if (!validateForm()) return; 
    setIsSubmitBtn(true);
    const form = document.getElementById('addSubscriberform');

    const formData = new FormData(form);
    formData.set('isRegByLink', 1);
    try {
      const response = await axios.post(`${config.API_URL}/ws_subscriber_add`, formData, {
        headers: {
          authorization: `Bearer`,
        }
      });

      const result = response.data;
      if (result.success == true){
          Swal.fire({
            title: "Success!",
            text: "Subscriber added successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => 
          {
            //  navigateToSupplierList(`/subscriber`);
          });

      }
      else
      {
        Swal.fire({
          title: "Failed!",
          text: "Subscriber add Failed. Try Again",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
      } catch (error) {
        console.error("Error accepting order:", error);
      }
  };

  const FetchPrice = async () => {
    try {
        const response = await axios.get(`${config.API_URL}/ws_get_price`);
        if (response.data.status) {
          const amount = parseFloat(response.data.data, 10);
          setPrice(amount);
          setTotalAmount(amount);
        } else {
            console.error('Error: ', response.data.message);
        }
    } catch (error) {
        console.error('Error fetching price:', error);
      }
  };

    useEffect(() => {
        FetchPrice();
    }, []);

  const calculateAmountChange = () => {
    let amount = 0;
    if(subscription != 0)
    {
       amount = subscription * price;
    }
      setTotalAmount(amount);   
    };

  useEffect(()=>{
    calculateAmountChange(); 
  },[subscription]);


  return (
    <div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard" style={{ position: 'relative' }}>
                <div className='add-supplier-popup'>
                  <form id='addSubscriberform'>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                            <label htmlFor="fname" className="col-form-label">First Name *</label>
                            <input type="text" className="form-control" name="fname" id="fname" value={formData.fname} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.fname && <span className="text-danger">{errors.fname}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                          <label htmlFor="mname" className="col-form-label">Middle Name </label>
                            <input type="text" className="form-control" name="mname" id="mname" value={formData.mname} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.mname && <span className="text-danger">{errors.mname}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                          <label htmlFor="lname" className="col-form-label">Last Name *</label>
                            <input type="text" className="form-control" name="lname" id="lname" value={formData.lname} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.lname && <span className="text-danger">{errors.lname}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                            <label htmlFor="email" className="col-form-label">Email Address *</label>
                            <input type="email" className="form-control" name="email" id="email" value={formData.email} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.email && <span className="text-danger">{errors.email}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                          <div className="col-sm-12">
                            <label htmlFor="phone" className="col-form-label">Phone Number *</label>
                            <input type="number" className="form-control" name="phone" id="phone" value={formData.phone} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.phone && <span className="text-danger">{errors.phone}</span>}
                          </div>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address" className="col-form-label">Address</label>
                        <input type="text" className="form-control" name="address" id="address" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address2" className="col-form-label">Line 2</label>
                        <input type="text" className="form-control" name="address2" id="address2" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="address3" className="col-form-label">Line 3</label>
                        <input type="text" className="form-control" name="address3" id="address3" />
                      </div>
                    </div>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label htmlFor="city" className="col-form-label">City</label>
                        <input type="text" className="form-control" name="city" id="city" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="state" className="col-form-label">State</label>
                        <input type="text" className="form-control" name="state" id="state" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="postal_code" className="col-form-label">Pin Code</label>
                        <input type="text" className="form-control" name="postal_code" id="postal_code" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="birthDate" className="col-form-label">Birth Date *</label>
                        <input type="date" className="form-control" name="birthDate" id="birthDate" />
                      </div>
                    </div>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                          <label htmlFor="subscriber_plan" className="col-form-label">Subscription</label>
                          <select
                              name="subscriber_plan"
                              value={subscription}
                              className="form-select orgselect"
                              onChange={(e) => setSubscription(parseInt(e.target.value))}
                          >
                              <option value="1">1 Year</option>
                              <option value="2">2 Years</option>
                              <option value="3">3 Years</option>
                              <option value="4">4 Years</option>
                              <option value="5">5 Years</option>
                              <option value="6">6 Years</option>
                              <option value="7">7 Years</option>
                              <option value="8">8 Years</option>
                              <option value="9">9 Years</option>
                              <option value="10">10 Years</option>
                          </select>
                      </div>
                      <div className="col-sm-3">
                          <label htmlFor="amount" className="col-form-label">Amount</label>
                          <input type="text" className="form-control" name="amount" id="amount" value={totalAmount} readOnly />
                      </div>
                  </div>
                    <div className='row mb-3 padd-l-13px'>
                    <div className='col-12 col-lg-12 col-sm-12'>
                      <button 
                          onClick={submitSubscriberForm}
                          type="button" 
                          className="float-right-btn common-popup-btn" 
                          disabled={isSubmitBtn}
                      >
                          Submit
                      </button>
                  </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Add; 